<div class="w-full bg-white d-flex align-items-center shadow-b border-bottom-light">
  <div class="px-3 py-1" style="border-right: 2px solid #dadada;"><img src="assets/teleapss_workbench.png"
      style="height: 32px"></div>
  <div class="w-100 ">
    <div class=" px-3 py-1 d-flex   justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <!--w-main -->
        <div style="font-size: 0.725rem;">
          <ng-content select="[slot-breadcrumb]"></ng-content>
        </div>
        <h3 class="headings mb-0">
          <ng-content select="[slot-heading]"></ng-content>
        </h3>
      </div>
      <div class="mr-auto ml-4">
        <ng-content select="[slot-options]"></ng-content>
      </div>
      <div class=" position-relative d-flex" style="height: 36px;">
        <div class="d-flex px-3 py-1  align-items-center">
          <!-- border-bottom-light shadow-b -->

          <div class="ml-auto position-relative d-flex justify-content-end">
            <div class="">
              <ng-content select="[slot-searchfilter]"></ng-content>
            </div>
            <div class="">
              <ng-content select="[slot-searchinput]"></ng-content>
            </div>
            <a class="btn context-menu" (click)="showContextMenu = !showContextMenu">
              <dots-horizontal-outline-icon></dots-horizontal-outline-icon>
            </a>
            <div *ngIf="showContextMenu" class="position-absolute bg-light border p-2"
              style="margin-top:42px; min-width:200px; z-index: 301;">
              <ul class="list-inline context-menu">
                <ng-content select="[slot-contextmenu]"></ng-content>
              </ul>
            </div>
          </div>
        </div>
        <div class="profile-menu d-flex align-items-center border rounded px-2 py-1" style="cursor: pointer;"
          (click)="showRightMenu = !showRightMenu">
          <user-circle-outline-icon style="height:32px !important"></user-circle-outline-icon>
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" width="12px" height="12px" viewBox="0 0 284.929 284.929"
            style="enable-background:new 0 0 284.929 284.929;" xml:space="preserve" class="ml-2 mr-1">
            <g>
              <path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
  L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
  c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
  c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z" />
            </g>
          </svg>
          <!--<span class="ml-2">John Doe</span> -->
        </div>
        <div *ngIf="showRightMenu" class="position-absolute bg-white border "
          style="min-width:250px; z-index: 301;right: 0; margin-top:42px;z-index: 999;">
          <div class="text-center py-3">
            <img src="assets/user.png" class="rounded-pill" style="width: 60px;">
            <p class="text-lg font-bold py-1 mb-0">Dinesh Kumar S</p>
            <p class="mb-0" style="text-transform: initial;">dineshkumar@gmail.com</p>
          </div>
          <ul class="list-inline right-menu mb-0 py-2 border-top border-bottom">
            <li class="px-4 py-2"><a href="#">Profile</a></li>
            <li class="px-4 py-2"> <a href="#">Settings</a></li>
            <li class="px-4 py-2"><a href="#">Sign Out</a></li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</div>
