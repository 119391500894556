<div class="" style="max-height:100vh; overflow-y: hidden;">
  <!-- min-width:calc(100vw - 180px); d-flex flex-column-->
  <app-page-header>
    <div slot-breadcrumb>
      <div class="d-flex align-items-center">
        <a href="#" class="ml-2">Jobs</a> <span class="mx-2 font-bold">|</span>
        <a href="">Jobs-List</a>
      </div>
    </div>
    <div slot-heading class="d-flex align-items-center">
      All Jobs
      <div class="position-relative">
        <div class="d-flex align-items-center" style="cursor: pointer;" (click)="showRightMenu = !showRightMenu">
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 213.333 213.333" style="enable-background:new 0 0 213.333 213.333;"
            xml:space="preserve" width="9px" height="9px" class="mx-1">
            <g>
              <g>
                <polygon points="0,53.333 106.667,160 213.333,53.333 		" />
              </g>
            </g>
          </svg>
          <!--<span class="ml-2">John Doe</span> -->
        </div>
        <div *ngIf="showRightMenu" class="position-absolute bg-white border"
          style="min-width:170px; z-index: 301;right: 0;margin-top: 10px;">
          <ul class="list-inline text-sm mb-0 py-1">
            <li class="px-3 py-2 d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="13px" height="13px" x="0" y="0"
                viewBox="0 0 408.576 408.576" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                <g>
                  <g xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <path
                        d="M204.288,0C91.648,0,0,91.648,0,204.288s91.648,204.288,204.288,204.288s204.288-91.648,204.288-204.288    S316.928,0,204.288,0z M318.464,150.528l-130.56,129.536c-7.68,7.68-19.968,8.192-28.16,0.512L90.624,217.6    c-8.192-7.68-8.704-20.48-1.536-28.672c7.68-8.192,20.48-8.704,28.672-1.024l54.784,50.176L289.28,121.344    c8.192-8.192,20.992-8.192,29.184,0C326.656,129.536,326.656,142.336,318.464,150.528z"
                        fill="#34d399" data-original="#000000" class="" />
                    </g>
                  </g>

                </g>
              </svg>
              <a href="#" class="mx-2">All Jobs</a>
            </li>
            <li class="px-3 py-2"> <a href="#">My Jobs</a></li>
            <li class="px-3 py-2"><a href="#">Archived Jobs</a></li>
            <hr style="margin:0 auto">
            <li class="px-3 py-2"><a href="#">Build New List</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div slot-contextmenu>
      <li><a href="#">Add Staff</a></li>
      <li><a href="#">Remove Staff</a></li>
      <li><a href="#">Assign Manager</a></li>
      <li><a href="#">Assign Account Manager</a></li>
    </div>
    <div slot-searchinput>
      <input class="form-control" type="search" placeholder="Search Job Records">
    </div>
    <div slot-searchfilter>
      <div class="btn border p-1 mx-2 rounded" (click)="showStats = !showStats">
        <chart-pie-outline-icon></chart-pie-outline-icon>
      </div>
    </div>
  </app-page-header>


  <app-page-content>
    <div *ngIf="showStats" class="d-flex flex-column border-bottom-light stat-section">
      <div class="stat-row">
        <div class="stat-card">
          <p class="stat-data">17</p>
          <h6 class="stat-heading">Total Jobs</h6>
        </div>
        <div class="stat-card">
          <p class="stat-data">5</p>
          <h6 class="stat-heading">Starting Today</h6>
        </div>
        <div class="stat-card">
          <p class="stat-data">7</p>
          <h6 class="stat-heading">Due This Week</h6>
        </div>
        <div class="stat-card">
          <p class="stat-data">1</p>
          <h6 class="stat-heading">Due Next Week</h6>
        </div>
        <div class="stat-card">
          <p class="stat-data">2</p>
          <h6 class="stat-heading">Over Due</h6>
        </div>
      </div>
      <!--bindings={
      "ng-reflect-ng-if": "true"
    }-->
    </div>

    <div style="margin:0">
      <app-joblist></app-joblist>
    </div>
  </app-page-content>
</div>
