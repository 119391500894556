<div class="d-flex flex-column" style="min-width:calc(100vw - 180px);max-height:100vh; overflow-y: hidden;">
  <app-page-header>
    <div slot-breadcrumb>Annuity Contract | Contract List</div>
    <div slot-heading>
      <div class="d-flex align-items-center">Active Contracts
        <div class="position-relative">
          <div class="d-flex align-items-center" style="cursor: pointer;" (click)="showRightMenu = !showRightMenu">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px" y="0px" viewBox="0 0 213.333 213.333" style="enable-background:new 0 0 213.333 213.333;"
              xml:space="preserve" width="9px" height="9px" class="mx-1">
              <g>
                <g>
                  <polygon points="0,53.333 106.667,160 213.333,53.333 		" />
                </g>
              </g>
            </svg>
            <!--<span class="ml-2">John Doe</span> -->
          </div>
          <div *ngIf="showRightMenu" class="position-absolute bg-white border"
            style="min-width:170px; z-index: 301;right: 0;margin-top: 10px;">
            <ul class="list-inline text-sm mb-0 py-1">
              <li class="pl-1 pr-2 py-2 d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="13px" height="13px" x="0" y="0"
                  viewBox="0 0 408.576 408.576" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                  <g>
                    <g xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path
                          d="M204.288,0C91.648,0,0,91.648,0,204.288s91.648,204.288,204.288,204.288s204.288-91.648,204.288-204.288    S316.928,0,204.288,0z M318.464,150.528l-130.56,129.536c-7.68,7.68-19.968,8.192-28.16,0.512L90.624,217.6    c-8.192-7.68-8.704-20.48-1.536-28.672c7.68-8.192,20.48-8.704,28.672-1.024l54.784,50.176L289.28,121.344    c8.192-8.192,20.992-8.192,29.184,0C326.656,129.536,326.656,142.336,318.464,150.528z"
                          fill="#34d399" data-original="#000000" class="" />
                      </g>
                    </g>

                  </g>
                </svg>
                <a href="#" class="mx-2">Active Contracts</a>
              </li>
              <li class="px-3 py-2"><a href="#">Archived Contracts</a></li>
              <li class="px-3 py-2"><a href="#">Quoted Contracts</a></li>
              <hr style="margin:0 auto">
              <li class="px-3 py-2"> <a href="#">My Contracts</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div slot-options>
      <select name="staffs" id="staffs" form="staffs" class="custom-select">
        <option value="1">Karthick Kumar</option>
        <option value="2">Muthu Kumar</option>
        <option value="3">Senthil Kumar</option>
      </select>
    </div>
    <div slot-contextmenu>
      <li><a href="#">Create New Contract</a></li>
      <li><a href="#">Export Data</a></li>
    </div>

  </app-page-header>
  <app-page-content>
    <div class="my-3" style="height: calc(100vh - 50px);overflow-y: auto !important;"
      x-data="{showContractTerms: false}">
      <h3 class="headings mb-0 mx-3">Edit Contract</h3>
      <div class="bg-white py-3 my-3 border mx-3 px-2">
        <form>
          <div class="row m-0">
            <div class="col-2 mb-4">
              <label class="text-sm">Contract Number</label>
              <input type="text" class="form-control text-sm">
            </div>
            <div class="col-10 mb-4">
              <label class="text-sm">Contract Name</label>
              <input type="text" class="form-control text-sm">
            </div>

          </div>
          <div class="row m-0">
            <div class="col-6 mb-4">
              <label class="text-sm">Customer</label>
              <select class="form-control text-sm">
                <option>Gegosoft</option>
                <option>Microsoft</option>
                <option>Google</option>
                <option>Yahoo</option>
              </select>
            </div>
            <div class="col-3 mb-4">
              <label class="text-sm">Contract Manager</label>
              <select class="form-control text-sm">
                <option>Karthick kumar</option>
                <option>Senthil kumar</option>
                <option>Muthu kumar</option>
              </select>
            </div>
            <div class="col-3 mb-4">
              <label class="text-sm">Account Manager</label>
              <select class="form-control text-sm">
                <option>Dinesh Kumar</option>
                <option>Dinesh karthick</option>
              </select>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-3 mb-4">
              <label class="text-sm">Start date</label>
              <input type="date" class="form-control text-sm">
            </div>
            <div class="col-3 mb-4">
              <label class="text-sm">End date</label>
              <input type="date" class="form-control text-sm">
            </div>
            <div class="col-3 mb-4">
              <label class="text-sm">Duration (month)</label>
              <input type="text" class="form-control text-sm" value="3">
            </div>
            <div class="col-3 mb-4">
              <label class="text-sm">Quote Reference</label>
              <input type="text" class="form-control text-sm" value="">
            </div>
          </div>
          <!-- <div class="col-4 mb-4">
              <label class="text-sm">Sale Value</label>
              <input type="text" class="form-control text-sm">
            </div>
            <div class="col-4 mb-4">
              <label class="text-sm">External costs</label>
              <input type="text" class="form-control text-sm">
            </div>
            <div class="col-4 mb-4">
              <label class="text-sm">Internal costs</label>
              <input type="text" class="form-control text-sm">
            </div> -->
          <div class="row m-0">
            <div class="col-12 mb-4">
              <label class="text-sm">Contract Description</label>
              <textarea type="text" class="form-control text-sm" rows="4"></textarea>
            </div>
          </div>
          <div class="col-12 custom-dropdown-menu text-xs mb-3" style="min-width: 680px;">
            <div class="d-flex justify-content-start align-items-center">
              <h6 class="font-bold">Contents</h6>
              <div>
                <div class="btn btn-sm btn-info mx-2 mb-2"
                  (click)="[showEditDataModal = true,showEditPreloadData = true]">
                  Pre-load from
                  DataSet</div>
                <a href="#" class="btn btn-sm btn-info mx-2 mb-2">Import from Excel</a>
              </div>
            </div>
            <!-- <table class="table text-xs support-table border w-100 mb-1">
                <thead>
                  <tr>
                    <th width="25%">Name</th>
                    <th width="30%">Description</th>
                    <th width="20%">Cost</th>
                    <th width="20%">Sell</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Vendor Contract</td>
                    <td><input type="text" class="form-control text-xs w-75"></td>
                    <td>
                      <div class="position-relative d-flex align-items-center">
                        <img src="assets/icons/dollar.svg" width="13" height="13" class="position-absolute m-2">
                        <input type="text" class="form-control text-xs px-4">
                      </div>
                    </td>
                    <td>
                      <div class="position-relative d-flex align-items-center">
                        <img src="assets/icons/dollar.svg" width="13" height="13" class="position-absolute m-2">
                        <input type="text" class="form-control text-xs px-4">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>TeleAppsHD</td>
                    <td><input type="text" class="form-control text-xs w-75"></td>
                    <td>
                      <div class="position-relative d-flex align-items-center">
                        <img src="assets/icons/dollar.svg" width="13" height="13" class="position-absolute m-2">
                        <input type="text" class="form-control text-xs px-4">
                      </div>
                    </td>
                    <td>
                      <div class="position-relative d-flex align-items-center">
                        <img src="assets/icons/dollar.svg" width="13" height="13" class="position-absolute m-2">
                        <input type="text" class="form-control text-xs px-4">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>TeleAppsPrepaid</td>
                    <td><input type="text" class="form-control text-xs w-75"></td>
                    <td>
                      <div class="position-relative d-flex align-items-center">
                        <img src="assets/icons/dollar.svg" width="13" height="13" class="position-absolute m-2">
                        <input type="text" class="form-control text-xs px-4">
                      </div>
                    </td>
                    <td>
                      <div class="position-relative d-flex align-items-center">
                        <img src="assets/icons/dollar.svg" width="13" height="13" class="position-absolute m-2">
                        <input type="text" class="form-control text-xs px-4">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>ExternalHD</td>
                    <td><input type="text" class="form-control text-xs w-75"></td>
                    <td>
                      <div class="position-relative d-flex align-items-center">
                        <img src="assets/icons/dollar.svg" width="13" height="13" class="position-absolute m-2">
                        <input type="text" class="form-control text-xs px-4">
                      </div>
                    </td>
                    <td>
                      <div class="position-relative d-flex align-items-center">
                        <img src="assets/icons/dollar.svg" width="13" height="13" class="position-absolute m-2">
                        <input type="text" class="form-control text-xs px-4">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>ExternalPrepaid</td>
                    <td><input type="text" class="form-control text-xs w-75"></td>
                    <td>
                      <div class="position-relative d-flex align-items-center">
                        <img src="assets/icons/dollar.svg" width="13" height="13" class="position-absolute m-2">
                        <input type="text" class="form-control text-xs px-4">
                      </div>
                    </td>
                    <td>
                      <div class="position-relative d-flex align-items-center">
                        <img src="assets/icons/dollar.svg" width="13" height="13" class="position-absolute m-2">
                        <input type="text" class="form-control text-xs px-4">
                      </div>
                    </td>
                  </tr>
                </tbody>

              </table> -->
            <div class="">

              <form [formGroup]="addressForm">
                <div formArrayName="addressList" style="max-width: calc(100vw - 85px);overflow-x: auto;" class="border">
                  <table class="table text-xs support-table mb-1" style="width: max-content;overflow-x: auto;">
                    <thead>
                      <tr>
                        <th width="30px">#</th>
                        <th width="120px">Vendor</th>
                        <th width="90px">Item code</th>
                        <th width="240px">Item Description</th>
                        <th width="5%">Qty</th>
                        <th width="5%" class="whitespace-no-wrap">Unit buy price</th>
                        <th width="5%">TA HD %</th>
                        <th width="5%">TA uplift %</th>
                        <th width="5%">TA Sales %</th>
                        <th width="10%" style="text-align:right">Total Sell</th>
                        <th width="2%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let address of addresses.controls; let i=index">
                        <!-- Address {{i+1}} -->
                        <td>
                          <div (click)="removeAddress(i)" class="flex-20"><img src="assets/icons/minus.svg" width="18"
                              height="18"></div>
                        </td>
                        <td> <select class="form-control text-sm">
                            <optgroup label="Vendor Contract">
                              <option value="Genesys">Genesys</option>
                              <option value="Avaya">Avaya</option>
                            </optgroup>
                            <option>Teleapps Prepaid</option>
                            <option>Teleapps HD</option>
                            <option>Teleapps Prepaid</option>
                            <option>External HD</option>
                          </select></td>
                        <td>
                          <input type="text" class="form-control text-xs px-2" placeholder="Item Code">
                        </td>
                        <td>
                          <textarea type="text" class="form-control text-xs" rows="2"
                            placeholder="Item Description"></textarea>
                        </td>
                        <td>
                          <input type="text" class="form-control text-xs px-2">
                        </td>
                        <td>
                          <div class="position-relative d-flex align-items-center">
                            <input type="text" class="form-control text-xs px-2">
                          </div>
                        </td>
                        <td>
                          <input type="text" class="form-control text-xs px-2">
                        </td>
                        <td>
                          <input type="text" class="form-control text-xs px-2">
                        </td>
                        <td>
                          <input type="text" class="form-control text-xs px-2">
                        </td>
                        <td colspan="1" rowspan="1" style="text-align:right;">
                          <span style="font-size: 0.85rem;padding:5px 10px; background-color: lightblue;"><strong>$
                              YY,YYY,YYYY.YY</strong></span>
                        </td>
                        <td class="position-relative" x-data="{showFinData: false}">
                          <calculator-outline-icon x-on:click="showFinData = !showFinData">
                          </calculator-outline-icon>
                          <div x-show="showFinData" x-on:click.away="showFinData = !showFinData"
                            class="shadow p-2 border"
                            style="position:absolute; top:15px; right:20px; width:465px; z-index:1000; background-color:#ffffff; height:55px">
                            <div class="d-flex">
                              <dl class="mx-3">
                                <dt>Unit Sell</dt>
                                <dd>$ XX,XXX,XXXX.XX</dd>
                              </dl>
                              <!-- <dl class="mx-2">
                                <dt>Total TA helpdesk uplift</dt>
                                <dd>$ XX,XXX,XXXX.XX</dd>
                              </dl>
                              <dl class="mx-2">
                                <dt>Total TA base HD revenue</dt>
                                <dd>$ XX,XXX,XXXX.XX</dd>
                              </dl> -->
                              <dl class="mx-3">
                                <dt>Total TA Sales revenue</dt>
                                <dd>$ XX,XXX,XXXX.XX</dd>
                              </dl>
                              <dl class="mx-3">
                                <dt>Total TA Hd revenue</dt>
                                <dd>$ XX,XXX,XXXX.XX</dd>
                              </dl>
                              <div class="d-flex justify-content-end">
                                <x-circle-outline-icon x-on:click="showFinData = !showFinData"
                                  style="justify-content: end;"></x-circle-outline-icon>
                              </div>
                            </div>
                          </div>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                  <div class="add-more-btn mx-3 my-3" (click)="addAddress()">Add</div>
                  <!--<div *ngFor="let address of addresses.controls; let i=index">
                      Address {{i+1}}
                      <div class="form-container" [formGroup]="address">

                        <div class="flex-50">
                          <input formControlName="streetAddress" placeholder='Street'>
                        </div>

                        <div class="flex-50">
                          <input formControlName="city" placeholder='City'>
                        </div>

                        <div class="flex-50">
                          <input formControlName="state" placeholder='State' />
                        </div>

                        <div class="flex-30">
                          <input formControlName="zip" placeholder='Zip' />
                        </div>

                        <button type="button" (click)="removeAddress(i)" class="flex-20">Remove</button>

                      </div>
                      <hr />
                    </div>
                    <button (click)="addAddress()">Add New Address</button> -->
                </div>

              </form>

            </div>
            <div class="d-flex  flex-column align-items-end justify-content-end mt-2">
              <div class="font-bold mt-3 ml-5" style="font-size: 0.9rem;">Total Buy : $ AAA,AAAA.AA</div>
              <div class="font-bold mt-3 ml-5" style="font-size: 0.9rem;">Total Sell : $ BBB,BBB.BB</div>
              <div class="font-bold mt-3 ml-5" style="font-size: 0.9rem;">TA Helpdesk Revenue : $ CCC,CCC.CC</div>
              <div class="font-bold mt-3 ml-5" style="font-size: 0.9rem;">TA Sales Revenue : $ DDD,DDD.DD</div>
            </div>
          </div>
          <!-- start -->
          <div class="col-12 custom-dropdown-menu text-xs mb-3" style="min-width: 680px;">
            <label class="font-bold">Supported Product List</label>

            <div class="">

              <form [formGroup]="supportForm">
                <div formArrayName="supportList">
                  <table class="table text-xs support-table border w-100 mb-1">
                    <thead>
                      <tr>
                        <th width="20%">Vendor</th>
                        <th width="20%">Product name</th>
                        <th width="12%">Product version</th>
                        <th width="40%">Description</th>
                        <th width=""></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let support of supports.controls; let i=index">
                        <!-- Address {{i+1}} -->
                        <td> <input type="text" class="form-control text-xs px-4"></td>
                        <td><input type="text" class="form-control text-xs"></td>
                        <td>


                          <input type="text" class="form-control text-xs px-4">

                        </td>
                        <td>
                          <textarea type="text" class="form-control text-sm" rows="2"></textarea>
                        </td>
                        <td>
                          <div (click)="removeSupport(i)" class="flex-20"><img src="assets/icons/minus.svg" width="18"
                              height="18"></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="add-more-btn mt-3" (click)="addSupport()">Add</div>

                </div>

              </form>

            </div>

          </div>
          <!-- end -->
          <!-- start -->
          <div class="col-12 custom-dropdown-menu text-xs mb-3" style="min-width: 680px;">
            <label class="font-bold">Contract Terms</label>
            <div class="d-flex align-items-center">
              <div class="mr-4 d-flex align-items-center" x-on:click="showContractTerms = false">
                <input type="radio" id="24hours" name="contract-terms" value="24hours">
                <label for="24hours" class="mx-1 mb-0 mt-1 font-weight-normal">24/7</label>
              </div>
              <div class="mr-4 d-flex align-items-center" x-on:click="showContractTerms = true">
                <input type="radio" id="scheduled" name="contract-terms" value="scheduled">
                <label for="scheduled" class="mx-1 mb-0 mt-1 font-weight-normal">Scheduled</label>
              </div>
            </div>
            <div x-show="showContractTerms" class="add-contract-terms my-3">
              <!-- contract terms start -->
              <div class="divTable business-hours-table">
                <div class="divTableBody">
                  <div class="divTableRow d-flex flex-lg-column  flex-md-row">
                    <div class="divTableCell">
                      <p class="text-sm mb-0" style="height: 2rem;"></p>
                    </div>
                    <div class="divTableCell">
                      <p class="text-xs mt-2 mb-0" style="height: 2rem;">Open</p>
                    </div>
                    <div class="divTableCell">
                      <p class="text-xs mt-2 mb-0" style="height: 2rem;">Close</p>
                    </div>
                  </div>
                  <!-- *** -->
                  <div class="divTableRow d-flex flex-lg-column  flex-md-row">
                    <div class="divTableCell">
                      <div class="form-check d-flex align-items-center  flex-grow-1 mb-2 mb-lg-0" style="height: 2rem;">
                        <input class="form-check-input mt-0" type="checkbox" value="" id="defaultCheck1">
                        <label class="form-check-label text-sm" for="defaultCheck1">Mon</label>
                      </div>
                    </div>
                    <div class="divTableCell">
                      <div class="px-2 px-lg-0 mb-2 " style="height: 2rem;">
                        <select class="form-control rounded-0 text-sm">
                          <option value="">00:00</option>
                        </select>
                      </div>
                    </div>
                    <div class="divTableCell">
                      <div class="px-2 px-lg-0 mb-2 " style="height: 2rem;">
                        <select class="form-control rounded-0 text-sm">
                          <option value="">00:00</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- *** -->
                  <!-- *** -->
                  <div class="divTableRow d-flex flex-lg-column  flex-md-row">
                    <div class="divTableCell">
                      <div class="form-check d-flex align-items-center  flex-grow-1 mb-2 mb-lg-0" style="height: 2rem;">
                        <input class="form-check-input mt-0" type="checkbox" value="" id="defaultCheck1">
                        <label class="form-check-label text-sm" for="defaultCheck1">Tue</label>
                      </div>
                    </div>
                    <div class="divTableCell">
                      <div class="px-2 px-lg-0 mb-2 " style="height: 2rem;">
                        <select class="form-control rounded-0 text-sm">
                          <option value="">00:00</option>
                        </select>
                      </div>
                    </div>
                    <div class="divTableCell">
                      <div class="px-2 px-lg-0 mb-2 " style="height: 2rem;">
                        <select class="form-control rounded-0 text-sm">
                          <option value="">00:00</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- *** -->
                  <!-- *** -->
                  <div class="divTableRow d-flex flex-lg-column  flex-md-row">
                    <div class="divTableCell">
                      <div class="form-check d-flex align-items-center  flex-grow-1 mb-2 mb-lg-0" style="height: 2rem;">
                        <input class="form-check-input mt-0" type="checkbox" value="" id="defaultCheck1">
                        <label class="form-check-label text-sm" for="defaultCheck1">Wed</label>
                      </div>
                    </div>
                    <div class="divTableCell">
                      <div class="px-2 px-lg-0 mb-2 " style="height: 2rem;">
                        <select class="form-control rounded-0 text-sm">
                          <option value="">00:00</option>
                        </select>
                      </div>
                    </div>
                    <div class="divTableCell">
                      <div class="px-2 px-lg-0 mb-2 " style="height: 2rem;">
                        <select class="form-control rounded-0 text-sm">
                          <option value="">00:00</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- *** -->
                  <!-- *** -->
                  <div class="divTableRow d-flex flex-lg-column  flex-md-row">
                    <div class="divTableCell">
                      <div class="form-check d-flex align-items-center  flex-grow-1 mb-2 mb-lg-0" style="height: 2rem;">
                        <input class="form-check-input mt-0" type="checkbox" value="" id="defaultCheck1">
                        <label class="form-check-label text-sm" for="defaultCheck1">Thu</label>
                      </div>
                    </div>
                    <div class="divTableCell">
                      <div class="px-2 px-lg-0 mb-2 " style="height: 2rem;">
                        <select class="form-control rounded-0 text-sm">
                          <option value="">00:00</option>
                        </select>
                      </div>
                    </div>
                    <div class="divTableCell">
                      <div class="px-2 px-lg-0 mb-2 " style="height: 2rem;">
                        <select class="form-control rounded-0 text-sm">
                          <option value="">00:00</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- *** -->
                  <!-- *** -->
                  <div class="divTableRow d-flex flex-lg-column  flex-md-row">
                    <div class="divTableCell">
                      <div class="form-check d-flex align-items-center  flex-grow-1 mb-2 mb-lg-0" style="height: 2rem;">
                        <input class="form-check-input mt-0" type="checkbox" value="" id="defaultCheck1">
                        <label class="form-check-label text-sm" for="defaultCheck1">Fri</label>
                      </div>
                    </div>
                    <div class="divTableCell">
                      <div class="px-2 px-lg-0 mb-2 " style="height: 2rem;">
                        <select class="form-control rounded-0 text-sm">
                          <option value="">00:00</option>
                        </select>
                      </div>
                    </div>
                    <div class="divTableCell">
                      <div class="px-2 px-lg-0 mb-2 " style="height: 2rem;">
                        <select class="form-control rounded-0 text-sm">
                          <option value="">00:00</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- *** -->
                  <!-- *** -->
                  <div class="divTableRow d-flex flex-lg-column  flex-md-row">
                    <div class="divTableCell">
                      <div class="form-check d-flex align-items-center  flex-grow-1 mb-2 mb-lg-0" style="height: 2rem;">
                        <input class="form-check-input mt-0" type="checkbox" value="" id="defaultCheck1">
                        <label class="form-check-label text-sm" for="defaultCheck1">Sat</label>
                      </div>
                    </div>
                    <div class="divTableCell">
                      <div class="px-2 px-lg-0 mb-2 " style="height: 2rem;">
                        <select class="form-control rounded-0 text-sm">
                          <option value="">00:00</option>
                        </select>
                      </div>
                    </div>
                    <div class="divTableCell">
                      <div class="px-2 px-lg-0 mb-2 " style="height: 2rem;">
                        <select class="form-control rounded-0 text-sm">
                          <option value="">00:00</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- *** -->
                  <!-- *** -->
                  <div class="divTableRow d-flex flex-lg-column  flex-md-row">
                    <div class="divTableCell">
                      <div class="form-check d-flex align-items-center  flex-grow-1 mb-2 mb-lg-0" style="height: 2rem;">
                        <input class="form-check-input mt-0" type="checkbox" value="" id="defaultCheck1">
                        <label class="form-check-label text-sm" for="defaultCheck1">Sun</label>
                      </div>
                    </div>
                    <div class="divTableCell">
                      <div class="px-2 px-lg-0 mb-2 " style="height: 2rem;">
                        <select class="form-control rounded-0 text-sm">
                          <option value="">00:00</option>
                        </select>
                      </div>
                    </div>
                    <div class="divTableCell">
                      <div class="px-2 px-lg-0 mb-2 " style="height: 2rem;">
                        <select class="form-control rounded-0 text-sm">
                          <option value="">00:00</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- *** -->
                </div>
              </div>
              <!-- contract terms end -->
            </div>
          </div>
          <!--end-->

          <!-- start -->
          <div class="col-12 custom-dropdown-menu text-xs mb-3" style="min-width: 680px;">
            <label class="font-bold">Service Level Agreements</label>
            <table class="table text-xs support-table border w-100 mb-1">
              <thead>
                <tr>
                  <th width="25%">Critical (Hrs)</th>
                  <th width="25%">High (Hrs)</th>
                  <th width="25%">Medium (Hrs)</th>
                  <th width="25%">Low (Hrs)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" class="form-control text-xs w-75"></td>
                  <td><input type="text" class="form-control text-xs w-75"></td>
                  <td><input type="text" class="form-control text-xs w-75"></td>
                  <td><input type="text" class="form-control text-xs w-75"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--end-->


          <div class="mx-3 my-3 py-3">
            <a class="text-white bg-blue-dark px-3 py-2 rounded text-sm" href="#">Submit</a>
            <a href="#" class="bg-light px-3 py-2 rounded text-sm border mx-2">Cancel</a>
          </div>
        </form>
      </div>
    </div>
    <!-- supported contents modal start -->
    <div class="modal-wrapper" *ngIf="showEditDataModal"></div>
    <div class="half-page-modal animate__animated animate__slideInRight" *ngIf="showEditPreloadData">
      <div class="modal-close-button" (click)="[showEditPreloadData = false, showEditDataModal = false]">&times;</div>
      <div class="half-page-modal-container">
        <h4 class="">Contents</h4>

        <div class="text-sm my-3 d-flex flex-column justify-content-start"
          style="min-width: 680px;height:-webkit-fill-available;">
          <div class="form-group my-2">
            <select classs="form-control" style="width:300px">
              <option class="">Avaya</option>
              <option class="">Genesys</option>
              <option class="">Jabra</option>
              <option class="">Microsoft</option>
            </select>
          </div>
          <div>
            <table class="table text-sm support-table border w-100 mb-1">
              <thead>
                <tr>
                  <th width="5%">
                    <input type="checkbox">
                  </th>
                  <th width="15%">Item Code</th>
                  <th width="20%">Name</th>
                  <th width="50%">Item Description</th>
                  <th width="20%" class="text-right">Unit Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input type="checkbox">
                  </td>
                  <td>Gen-001</td>
                  <td>Genesys Cloud 1</td>
                  <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                  <td class="text-right">$ 100.00</td>
                </tr>
                <tr>
                  <td>
                    <input type="checkbox">
                  </td>
                  <td>Gen-002</td>
                  <td>Genesys Cloud 2</td>
                  <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                  <td class="text-right">$ 200.00</td>
                </tr>
                <tr>
                  <td>
                    <input type="checkbox">
                  </td>
                  <td>Gen-003</td>
                  <td>Genesys Cloud 3</td>
                  <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                  <td class="text-right">$ 300.00</td>
                </tr>
                <tr>
                  <td>
                    <input type="checkbox">
                  </td>
                  <td>Gen-004</td>
                  <td>Genesys Cloud 4</td>
                  <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                  <td class="text-right">$ 400.00</td>
                </tr>
              </tbody>

            </table>
          </div>
          <div>
            <div class="add-more-btn mx-3 my-3">Insert Data</div>
            <div class="cancel-btn mx-0 my-3">cancel</div>
          </div>
        </div>
      </div>
    </div>
    <!-- supported contents modal end -->
  </app-page-content>
</div>