<div class="d-flex flex-column" style="min-width:calc(100vw - 180px);max-height:100vh; overflow-y: hidden;">
  <app-page-header>
    <div slot-breadcrumb>
      <div class="d-flex align-items-center">
        <a href="#" class="ml-1">Jobs</a> <span class="mx-1 font-bold">|</span>
        <a routerlink="/job" class="ml-1">Jobs List</a> <span class="mx-1 font-bold">|</span>
        <a href="">Job Detail</a>
      </div>
    </div>
    <div slot-heading class="d-flex align-items-center">
      Jobs No : J0001
    </div>
    <div slot-contextmenu>
      <li><a href="#">Add Staff</a></li>
      <li><a href="#">Remove Staff</a></li>
      <li><a href="#">Assign Manager</a></li>
      <li><a href="#">Assign Account Manager</a></li>
    </div>
  </app-page-header>

  <app-page-content>
    <div class="m-0 p-0" style="overflow-y: auto;">
      <div class="custom-tab my-2">
        <!--<ul class="mx-3 d-flex list-inline tab-menu border-bottom-light">
        <li class="m-0 px-3 py-2 active"><a href="" class="">Information</a></li>
        <li class="mx-3 px-3 py-2"><a href="">Timesheet</a></li>
        <li class="m-0 px-3 py-2"><a href="">Notes</a></li>
        <li class="m-0 px-3 py-2"><a href="">Documents</a></li>
        <li class="m-0 px-3 py-2"><a href="">Costs</a></li>
        <li class="m-0 px-3 py-2"><a href="">Support</a></li>
        <li class="m-0 px-3 py-2"><a href="">Financial</a></li>
      </ul>-->
        <ul class="nav nav-tabs custom-nav-tabs text-xs" role="tablist">
          <li class="nav-item px-2">
            <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Information</a>
          </li>
          <li class="nav-item px-2">
            <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Timesheet</a>
          </li>
          <li class="nav-item px-2">
            <a class="nav-link" data-toggle="tab" href="#tabs-3" role="tab">Notes</a>
          </li>
          <li class="nav-item px-2">
            <a class="nav-link" data-toggle="tab" href="#tabs-4" role="tab">Documents</a>
          </li>
          <li class="nav-item px-2">
            <a class="nav-link" data-toggle="tab" href="#tabs-5" role="tab">Costs</a>
          </li>
          <li class="nav-item px-2">
            <a class="nav-link" data-toggle="tab" href="#tabs-6" role="tab">Support</a>
          </li>
          <li class="nav-item px-2">
            <a class="nav-link" data-toggle="tab" href="#tabs-7" role="tab">Financial</a>
          </li>
        </ul><!-- Tab panes -->
        <div class="tab-content px-3 py-2 text-sm">
          <div class="tab-pane active py-3" id="tabs-1" role="tabpanel">
            <div>
              <h5 class="font-bold text-sm">Job Information</h5>
              <div class="py-2 text-xs">
                <div class="d-flex align-items-center">
                  <p class="col-2"> Job No :</p>
                  <p>J00001</p>
                </div>
                <div class="d-flex align-items-center">
                  <p class="col-2"> Client :</p>
                  <p>3M Australia (TELSTRA)</p>
                </div>
                <div class="d-flex align-items-center">
                  <p class="col-2"> Name :</p>
                  <p>Test job 1</p>
                </div>
                <div class="d-flex align-items-center">
                  <p class="col-2"> Description :</p>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
                <div class="d-flex align-items-center">
                  <p class="col-2"> State :</p>
                  <p>Planned</p>
                </div>
                <div class="d-flex align-items-center">
                  <p class="col-2"> Quote Owner :</p>
                  <p>testuser</p>
                </div>
                <div class="d-flex align-items-center">
                  <p class="col-2"> Contact :</p>
                  <p>admin</p>
                </div>
                <div class="d-flex align-items-center">
                  <p class="col-2"> Client Order No :</p>
                  <p>---</p>
                </div>
                <div class="d-flex align-items-center">
                  <p class="col-2"> Category :</p>
                  <p>---</p>
                </div>
              </div>
            </div>
            <div>
              <h5 class="font-bold text-sm">Schedule Information </h5>
              <div class="py-2 text-xs">
                <div class="d-flex align-items-center">
                  <p class="col-2"> State Date:</p>
                  <p>30-Dec-2020</p>
                </div>
                <div class="d-flex align-items-center">
                  <p class="col-2"> Due Date: </p>
                  <p>31-Dec-2020</p>
                </div>
                <div class="d-flex align-items-center">
                  <p class="col-2"> Priority :</p>
                  <p>Normal</p>
                </div>
                <div class="d-flex align-items-center">
                  <p class="col-2"> Account Manager :</p>
                  <p>testuser</p>
                </div>
                <div class="d-flex align-items-center">
                  <p class="col-2"> Job Manager :</p>
                  <p>admin</p>
                </div>
                <div class="d-flex align-items-center">
                  <p class="col-2"> Staff :</p>
                  <p>testuser,testuser2</p>
                </div>
              </div>
            </div>

            <div>
              <h5 class="font-bold text-sm">Payment Milestone</h5>
              <div class="py-2 text-xs">
                <table class="table table-striped text-xs">
                  <thead>
                    <tr class="bg-light">
                      <th width="10%">Milestone Type</th>
                      <th width="20%">Label</th>
                      <th width="50%">Milestone %</th>
                      <th width="10%">Baseline</th>
                      <th width="10%">Actual</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Milestone 1</td>
                      <td>invoice</td>
                      <td>100 </td>
                      <td>22-Nov-2020</td>
                      <td>29-Nov-2020</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <h5 class="font-bold text-sm">Milestones</h5>
                <a href="" class="bg-blue-dark text-white text-xs px-3 py-1">New Milestones</a>
              </div>
              <div class="py-2 text-xs">
                <table class="table table-striped text-xs">
                  <thead>
                    <tr class="bg-light">
                      <th><input type="checkbox"></th>
                      <th>Date</th>
                      <th>Milestone</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><input type="checkbox"></td>
                      <td>30-Dec-2020</td>
                      <td>100 </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="font-bold text-sm">Tasks</h5>
                <a href="" class="bg-blue-dark text-white text-xs px-3 py-1">Staff Allocation</a>
              </div>
              <div class="py-2 text-xs">
                <div class="custom-tab">
                  <ul class="nav nav-tabs custom-nav-tabs text-xs" role="tablist">
                    <li class="nav-item px-2">
                      <a class="nav-link active" data-toggle="tab" href="#tabs-8" role="tab">Information</a>
                    </li>
                    <li class="nav-item px-2">
                      <a class="nav-link" data-toggle="tab" href="#tabs-9" role="tab">Timesheet</a>
                    </li>
                  </ul>
                  <div class="tab-content py-2 text-sm">
                    <div class="tab-pane active py-3" id="tabs-8" role="tabpanel">
                      <div class="py-2 text-xs">
                        <table class="table table-striped text-xs">
                          <thead>
                            <tr class="bg-light">
                              <th><input type="checkbox"></th>
                              <th>Name</th>
                              <th>Start</th>
                              <th>Due</th>
                              <th>Estimated</th>
                              <th>Actual</th>
                              <th>Remaining</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><input type="checkbox"></td>
                              <td>Presales Activities</td>
                              <td>30-Dec-2020 </td>
                              <td>31-Dec-2020</td>
                              <td>4:00</td>
                              <td>0:00</td>
                              <td>4:00</td>
                            </tr>
                            <tr>
                              <td><input type="checkbox"></td>
                              <td>$ 1320 Presales Activities</td>
                              <td>30-Dec-2020 </td>
                              <td>31-Dec-2020</td>
                              <td>4:00</td>
                              <td>2:00</td>
                              <td>2:00</td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="tab-pane py-3" id="tabs-9" role="tabpanel">
                      <div class="py-2 text-xs">
                        <table class="table table-striped text-xs">
                          <thead>
                            <tr class="bg-light">

                              <th>Name</th>
                              <th>Estimated</th>
                              <th>Actual</th>
                              <th>Remaining</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>$1320 Presales Activities</td>
                              <td>4:00</td>
                              <td>0:00</td>
                              <td>4:00</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane py-3" id="tabs-2" role="tabpanel">
            <div>
              <h5 class="font-bold text-sm">Timesheet Information</h5>
              <form>
                <div class="d-flex flex-column py-2 my-1">
                  <label class="col-2 text-xs">Date</label>
                  <div class="col-4">
                    <input type="date" class="border w-100 py-1 px-2 text-xs">
                  </div>
                </div>
                <div class="d-flex flex-column py-2 my-1">
                  <label class="col-2 text-xs">Staff</label>
                  <div class="col-4">
                    <select class="border w-100 py-1 px-2 text-xs">
                      <option>Select Staff</option>
                    </select>
                  </div>
                </div>
                <div class="d-flex flex-column py-2 my-1">
                  <label class="col-2 text-xs">Task</label>
                  <div class="col-4">
                    <select class="border w-100 py-1 px-2 text-xs">
                      <option>Select Task</option>
                    </select>
                  </div>
                </div>
                <div class="d-flex flex-column py-2 my-1">
                  <label class="col-2 text-xs">Time</label>
                  <div class="col-4">
                    <input type="text" class="border w-100 py-1 px-2 text-xs">
                  </div>
                </div>
                <div class="d-flex flex-column py-2 my-1">
                  <label class="col-2 text-xs">Notes</label>
                  <div class="col-4">
                    <textarea type="text" class="border w-100 py-1 px-2 text-xs" rows="3"></textarea>
                  </div>
                </div>

                <div class="col-10 my-3">
                  <a href="#" class="text-white bg-blue-dark px-4 py-2 rounded text-xs">Save</a>
                  <a href="#" class="bg-gray-light px-4 py-2 rounded text-xs mx-2">Reset</a>
                </div>
              </form>

            </div>
          </div>
          <div class="tab-pane py-3" id="tabs-3" role="tabpanel">
            <p>Notes Panel</p>
          </div>
          <div class="tab-pane py-3" id="tabs-4" role="tabpanel">
            <p>Documents Panel</p>
          </div>
          <div class="tab-pane py-3" id="tabs-5" role="tabpanel">
            <p>Costs Panel</p>
          </div>
          <div class="tab-pane py-3" id="tabs-6" role="tabpanel">
            <p>Support Panel</p>
          </div>
          <div class="tab-pane py-3" id="tabs-7" role="tabpanel">
            <p>Financial Panel</p>
          </div>
        </div>
      </div>
    </div>
  </app-page-content>
</div>
