import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  name = 'Angular';
  constructor() { }


  ngOnInit(): void {
  }

  showContextMenu: boolean = false;
  //@Input() showStats: boolean = PageHeaderComponent;
  showStats: boolean = true;
  showRightMenu: boolean = false;
  showFilter: boolean = true;
  public showDataModal: boolean = false;
  public showPage: string = '';
  renewContract() {
    Swal.fire({
      title: '<div class="d-flex flex-column align-items-start w-100"><p class="pb-4">Renew Contract</p><div class="row m-0 w-100 justify-content-between"><div class="col-6 mb-4 text-left d-flex align-items-center p-0"><label class="text-xs mb-0  col-3 p-0">Duration</label><select class="form-control text-sm"><option>1 Month</option><option>2 Month</option><option>3 Month</option></select></div><div class="col-4 mb-4 text-left d-flex align-items-center p-0 justify-content-between"><label class="text-xs mb-0 mr-2">End Date</label><input type="date" class="form-control text-sm col-7"></div></div><div class="row m-0 w-100"><div class="col-12 mb-4 text-left d-flex  p-0"><label class="text-xs mb-0 mr-2 col-1 p-0">Note</label><div class="col-11 p-0 pr-2 pl-4"><textarea type="text" class="form-control text-sm"></textarea></div></div></div><div class=" m-0 w-100"><label class="text-xs mb-2 mr-2 w-100 text-left p-0">Contact Person</label><div class="row w-100 m-0"><div class="col-4 p-1"><input type="text" class="form-control text-sm " placeholder="Person Name"></div><div class="col-4 p-1"><input type="text" class="form-control text-sm" placeholder="Email"></div><div class="col-4 p-1"><input type="text" class="form-control text-sm" placeholder="Mobile"></div></div></div><div>',
      //text: '<div></div>',
      //type: 'info',

      confirmButtonText: 'Renew Contract',
      showCancelButton: true,
      showCloseButton: true,
    })
  }
  endContract() {
    Swal.fire({
      title: '<div class="d-flex flex-column align-items-start w-100"><p class="pb-4">End Contract</p><div class="row m-0 w-100 justify-content-between"><div class="col-4 mb-4 text-left d-flex align-items-center p-0 justify-content-between"><label class="text-xs mb-0">End Date</label><input type="date" class="form-control text-sm col-7"></div></div><div class="row m-0 w-100"><div class="col-12 mb-4 text-left d-flex  p-0"><label class="text-xs mb-0 mr-2 col-1 p-0">Reason</label><div class="col-11 p-0 pr-2 pl-4"><textarea type="text" class="form-control text-sm"></textarea></div></div></div><div class=" m-0 w-100"><label class="text-xs mb-2 mr-2 w-100 text-left p-0">Contact Person</label><div class="row w-100 m-0"><div class="col-4 p-1"><input type="text" class="form-control text-sm " placeholder="Person Name"></div><div class="col-4 p-1"><input type="text" class="form-control text-sm" placeholder="Email"></div><div class="col-4 p-1"><input type="text" class="form-control text-sm" placeholder="Mobile"></div></div></div><div>',
      //text: '<div></div>',
      //type: 'info',
      confirmButtonText: 'End Contract',
      showCancelButton: true,
      showCloseButton: true,
    })
  }
}
