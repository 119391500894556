import { Component, Input, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../page-header/page-header.component';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  showContextMenu: boolean = false;
  //@Input() showStats: boolean = PageHeaderComponent;
  showStats: boolean = true;
  showRightMenu: boolean = false;
  showFilter: boolean = true;

}
