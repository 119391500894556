
import { Component, OnInit } from '@angular/core';
import { jobs } from './jobs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskListComponent } from '../task-list/task-list.component';

type type1 = 'tasks' | 'notes' | 'milestones';

@Component({
  selector: 'app-joblist',
  templateUrl: './joblist.component.html',
  styleUrls: ['./joblist.component.scss']
})
export class JoblistComponent implements OnInit {


  public jobs = jobs;
  public showOptionsMenu: boolean = false;
  public showLastNote: boolean = false;
  public showStatusMenu: boolean = false;
  public showStaffsMenu: boolean = false;
  public showPercentage: boolean = false;
  public showData: string = '';
  public showCalendar: boolean = false;
  public showDataModal: boolean = false;
  public showConfirmModal: boolean = false;
  public showPage: string = '';
  public pageData: any;
  public showTaskNotes: boolean = false;
  public showTaskNoteId: string = '';

  public ngOnInit(): void {

  }



}

