import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-navigation',
  templateUrl: './sidebar-navigation.component.html',
  styleUrls: ['./sidebar-navigation.component.scss']
})
export class SidebarNavigationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  showSidebar: boolean = false;
}
