import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { BlankLayoutComponent } from './blank-layout/blank-layout.component';
import { ContactsComponent } from './contacts/contacts.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FinancialComponent } from './financial/financial.component';
import { JobsComponent } from './jobs/jobs.component';
import { LoginComponent } from './login/login.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { QuotesComponent } from './quotes/quotes.component';
import { SupportComponent } from './support/support.component';
import { TimesheetsComponent } from './timesheets/timesheets.component';
import { JobsdetailComponent } from './jobsdetail/jobsdetail.component';
import { AddSupportComponent } from './support/add-form/add-form.component';
import { EditSupportComponent } from './support/edit-form/edit-form.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent }
    ]
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'contacts', component: ContactsComponent },
      { path: 'quotes', component: QuotesComponent },
      { path: 'jobs', component: JobsComponent },
      { path: 'support', component: SupportComponent },
      { path: 'timesheets', component: TimesheetsComponent },
      { path: 'financial', component: FinancialComponent },
      { path: 'admin', component: AdminComponent },
      { path: 'job-details', component: JobsdetailComponent },
      { path: 'support/add', component: AddSupportComponent },
      { path: 'support/edit', component: EditSupportComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
