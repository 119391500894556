<!--<h1>login works!</h1>-->
<div class="py-5 my-5">
  <div class="row">
    <div class="col-12 col-lg-4 col-md-6 mx-auto">
      <div class="d-flex justify-content-center">
        <img src="assets/teleapss_workbench.png" style="width:200px; height:auto; margin:10px auto">
      </div>

      <div class="bg-white py-3 px-5 my-4 border">
        <h4 class="font-bold py-2">Sign in to continue</h4>
        <div class="mb-4">
          <div class="form__group field text-sm">
            <input type="input" class="form__field" placeholder="Name" name="name" id='name' required />
            <label for="name" class="form__label">Username Or Email</label>
          </div>

        </div>
        <div class="mb-4">
          <div class="form__group field text-sm">
            <input type="input" class="form__field" placeholder="Name" name="name" id='name' required />
            <label for="name" class="form__label">Password</label>
          </div>
        </div>
        <div class="mb-4 mt-5 d-flex align-items-center justify-content-between">
          <div><a routerLink="/dashboard" class="text-white bg-blue-dark px-4 py-2 rounded text-sm">Sign in</a></div>
          <div class="text-sm"><a href="#">Forgot Password ?</a></div>
        </div>
      </div>
    </div>
  </div>
</div>