<div class="d-flex flex-column" style="min-width:calc(100vw - 180px);max-height:100vh; overflow-y: hidden;">
  <app-page-header>
    <div slot-breadcrumb>Finace</div>
    <div slot-heading>Finance</div>
    <div slot-contextmenu>
      <li><a href="#">Add Expense</a></li>
      <li><a href="#">Export Data</a></li>
    </div>
  </app-page-header>
  <app-page-content>
    Finance Table Here
  </app-page-content>
</div>
