<div class="m-0 p-0" style="overflow-y: auto;">
  <!-- min-height: calc(100vh - 180px); -->
  <div class="d-flex">
    <!-- m-3 -->
    <app-sidebar-navigation></app-sidebar-navigation>
    <div class="w-100">
      <ng-content></ng-content>
    </div>
  </div>
</div>
