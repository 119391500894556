<div class="d-flex flex-column">
  <div class="w-main d-flex flex-column bg-white ">

  </div>
  <div class="mx-3 flex-fill my-4" style="min-height:calc(100vh -200px);width:calc(100vw - 120px); margin-top: 5px; overflow-x: scroll;overflow-y: auto;
    max-height: calc(100vh - 210px);">

    <table class="table text-sm dataTable" style="overflow-x: scroll; width:2000px !important">
      <thead>
        <th width="18px">
          <div class=""><input type="checkbox"></div>
        </th>

        <th width="120px">
          <div class="d-flex justify-content-between">
            <div class="flex-grow-1">Job Id</div>
          </div>
        </th>
        <th width="350px">
          Client Name
        </th>
        <th width="100px">
          <!-- style="text-align: center;" -->
          Status
        </th>
        <th width="380px">
          Job Name
        </th>
        <th width="150px">
          % Completed
        </th>
        <th width="150px">
          Staff
        </th>

        <th width="130px">
          PO Date
        </th>
        <th width="130px">
          Start Date
        </th>
        <th width="130px">
          Due Date
        </th>
        <th width="100px">
          Budgeted
        </th>
        <th width="110px">
          Remaining
        </th>

      </thead>
      <tbody>
        <tr class="p-0 m-0" *ngFor="let job of jobs;">
          <td>
            <input type="checkbox">
          </td>
          <!-- <td class="position-relative">
            <div class="position-relative btn no-button">
              <a (click)="[showData = job.id, showOptionsMenu = !showOptionsMenu] ">
                <dots-vertical-solid-icon style="color:#cacaca;"></dots-vertical-solid-icon>
              </a>
            </div>
            <div *ngIf="showData === job.id && showOptionsMenu" class="position-absolute custom-dropdown-menu">
              <ul class="custom-options-menu">
                <li class="font-bold"
                  (click)="[showDataModal = true, showPage = 'task' , showOptionsMenu = !showOptionsMenu,  pageData= job.tasks]">
                  View
                  Tasks</li>
                <li class="font-bold"
                  (click)="[showDataModal = true, showPage = 'notes' , showOptionsMenu = !showOptionsMenu,  pageData= job.notes]">
                  View
                  Notes</li>
                <li class="font-bold"
                  (click)="[showDataModal = true, showPage = 'milestones' , showOptionsMenu = !showOptionsMenu,  pageData= job.milestones]">
                  View Milstones</li>
              </ul>
            </div>
          </td> -->
          <td>
            <a href="https://workflowmax.com/job/{{job.id}}">{{ job.id }}</a>
          </td>
          <td>
            {{ job.clientName }}
          </td>
          <td style="text-align: left;">
            <div class="position-relative">
              <a (click)="[showData = job.id, showStatusMenu = !showStatusMenu] "><span
                  [ngClass]="{'active-job': job.jobStatus === 'Active', 'onhold-job': job.jobStatus === 'On Hold'}">{{
                  job.jobStatus }}</span></a>
              <div *ngIf="showData === job.id && showStatusMenu" class="position-absolute custom-dropdown-menu">
                <ul class="custom-options-menu">
                  <li class="font-bold"
                    (click)="[showDataModal = true, showConfirmModal = true , showStatusMenu = false]">
                    On Hold</li>
                  <li class="font-bold"
                    (click)="[showDataModal = true, showConfirmModal = true , showStatusMenu = false]">
                    Complete</li>
                </ul>
              </div>
            </div>
          </td>
          <td class="position-relative">
            <clipboard-list-outline-icon (mouseenter)="[showData = job.id, showLastNote = true]"
              (mouseleave)="[showData = job.id, showLastNote = false]"
              (click)="[showDataModal = true, showPage = 'notes' , showOptionsMenu = !showOptionsMenu,  pageData= job.notes]">
            </clipboard-list-outline-icon> {{ job.jobName }}
            <div *ngIf="showData === job.id && showLastNote" class="position-absolute  custom-dropdown-menu">
              <ul class="custom-options-menu">
                {{ job.lastNote }}
              </ul>
            </div>
          </td>
          <td class="position-relative">
            <div class="d-flex">
              <bookmark-alt-outline-icon style="margin-right:2px;" title="Milestones"
                (click)="[showDataModal = true, showPage = 'milestones' , showOptionsMenu = !showOptionsMenu,  pageData= job.milestones]">
              </bookmark-alt-outline-icon>
              <clipboard-check-outline-icon style="margin-right:2px;" title="Task Lists"
                (click)="[showDataModal = true, showPage = 'task' , showOptionsMenu = !showOptionsMenu,  pageData= job.tasks]">
              </clipboard-check-outline-icon>
              <div class="flex-grow-1" height="30px" style="border:2px solid #cacaca"
                (click)="[showData = job.id, showPercentage = !showPercentage]">

                <div style="background-color:limegreen; height:24px;" [style.width.px]="job.percentComplete">

                </div>
                <div style="margin-top:-22px; color: #333; font-weight: bold; padding:0 10px;">{{ job.percentComplete }}
                  %</div>
              </div>
              <div *ngIf="showData === job.id && showPercentage" class="position-absolute custom-dropdown-menu my-4"
                style="right: 0;top: 12px;">

                <div class="px-1">
                  <div class="d-flex align-items-center justify-content-between mb-1">
                    <label class="text-xs font-bold mb-0">Percentage</label>
                    <div class="" (click)="[showData = job.id, showPercentage = !showPercentage]">×</div>
                  </div>
                  <input type="number" class="form-control" min="1" max="100" style="width: 120px;">
                </div>
              </div>
            </div>
          </td>
          <td class="position-relative">
            <div class="position-relative">{{ job.primaryStaffName
              }}<span class="staff-more" (click)="[showData = job.id, showStaffsMenu = !showStaffsMenu]">
                {{job.staffs.length }}+
              </span></div>
            <div *ngIf="showData === job.id && showStaffsMenu" class="position-absolute custom-dropdown-menu">
              <ul class="custom-options-menu">
                <li *ngFor="let staff of job.staffs">{{ staff.staffName }}</li>
              </ul>
            </div>
          </td>
          <td>
            {{job.poDate }}</td>
          <td>
            {{job.startDate }}</td>
          <td class="position-relative">

            <div class=" btn no-button">
              <!--position-relative -->
              <a (click)="[showData = job.id, showCalendar = !showConfirmModal] " title="Calendar">
                {{job.dueDate}} <calendar-outline-icon></calendar-outline-icon>
              </a>
            </div>
            <div *ngIf="showData === job.id && showCalendar" class="d-block custom-dropdown-menu position-absolute">
              <!--position-relative -->
              <ul class="custom-options-menu">
                Calendar comes here
              </ul>


            </div>

          </td>
          <td>
            {{ job.hoursBudgeted }} Hrs
          </td>
          <td>
            {{ job.hoursRemaining }} Hrs
          </td>
        </tr>
      </tbody>


    </table>

  </div>
</div>
<div class="modal-wrapper" *ngIf="showDataModal">
  <div class="half-page-modal animate__animated animate__slideInRight" *ngIf="showPage === 'task'">
    <div class="modal-close-button" (click)="[showPage = '',showDataModal= false]">&times;
    </div>
    <div class="half-page-modal-container">
      <h4 class="">Tasks List</h4>
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Task Name</th>
            <th>Assigned To</th>
            <th>Status</th>
            <th>Start Date</th>
            <th>Due Date</th>
            <th>Progress</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>

          <tr *ngFor="let pageDataItem of pageData">
            <td class="table-cell">
              <p class="mb-0 d-flex align-items-center">
                <clipboard-list-outline-icon
                  (mouseenter)="[showTaskNoteId = pageDataItem.taskId, showTaskNotes = !showTaskNotes]"
                  (mouseleave)="[showTaskNoteId = pageDataItem.taskId, showTaskNotes = !showTaskNotes]"
                  style="cursor: pointer;">
                </clipboard-list-outline-icon>{{
                pageDataItem.taskDescription }}
              </p>
              <div *ngIf="showTaskNoteId === pageDataItem.taskId && showTaskNotes"
                class="position-absolute custom-dropdown-menu text-xs">
                Note added for that Task
              </div>

            </td>

            <td class="table-cell">{{ pageDataItem.taskAssignedTo }}</td>
            <td class="table-cell">{{ pageDataItem.taskStatus }}</td>
            <td class="table-cell">{{ pageDataItem.taskStartAt }}</td>
            <td class="table-cell">{{ pageDataItem.taskDueAt }}</td>
            <td class="table-cell">
              <div class="flex-grow-1" height="20px" style="border:2px solid #cacaca">
                <div style="background-color:limegreen; height:20px;width: 50px;">
                </div>
                <div style="margin-top:-21px; color: #333; font-weight: bold; padding:0 10px;">50
                  %</div>
              </div>
            </td>
            <td>
              <a href="https://workflowmax.com/task#" class="btn btn-link text-xs">Edit Task</a>
            </td>

          </tr>

        </tbody>

      </table>
    </div>
  </div>

  <div class="half-page-modal animate__animated animate__slideInRight" *ngIf="showPage === 'notes'">
    <div class="modal-close-button" (click)="[showPage = '',showDataModal= false]">&times;</div>
    <div class="half-page-modal-container">
      <h4 class="">Notes</h4>
      <div x-data="{ tabOpen: 'tabOne' }">
        <ul class="d-flex text-xs notes-tab mb-0 p-0">
          <li x-on:click.prevent="tabOpen = 'tabOne'" x-bind:class="{ 'active': tabOpen === 'tabOne'} "
            class="py-1 px-3 font-bold">List
          </li>
          <li x-on:click.prevent="tabOpen = 'tabTwo'" x-bind:class="{ 'active': tabOpen === 'tabTwo'} "
            class="py-1 px-3 font-bold mx-4">Add Note
          </li>

        </ul>
        <div x-show="tabOpen === 'tabOne'" class="">
          <table class="table table-striped table-sm mt-2">
            <thead>
              <tr>
                <th width="60%">Description</th>
                <th>Added On</th>
                <th>Added By</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let pageDataItem of pageData">
                <td class="table-cell">{{ pageDataItem.noteDescription }}</td>
                <td class="table-cell">{{ pageDataItem.noteAddedOn }}</td>
                <td class="table-cell">{{ pageDataItem.noteAddedBy }}</td>
              </tr>
            </tbody>

          </table>
        </div>
        <div x-show="tabOpen === 'tabTwo'" class="border p-3 mt-2">
          <form>
            <div class="mb-3">
              <label class="text-xs font-bold">Note</label>
              <textarea class="form-control" rows="4"></textarea>
            </div>
            <div class="mb-2 pt-3">
              <a class="text-white bg-blue-dark px-4 py-2 rounded text-xs" href="#">Add </a>
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>
  <div class="half-page-modal animate__animated animate__slideInRight" *ngIf="showPage === 'milestones'">
    <div class="modal-close-button" (click)="[showPage = '',showDataModal= false]">&times;</div>
    <div class="half-page-modal-container">
      <h4 class="">Milestones</h4>
      <table class="table table-striped">
        <thead>
          <tr>
            <th width="60%">Milestone Name</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let pageDataItem of pageData">
            <td class="table-cell">{{ pageDataItem.milestoneName }}</td>
            <td class="table-cell">{{ pageDataItem.milestoneDate }}</td>
          </tr>
        </tbody>

      </table>
    </div>
  </div>
</div>
<div class="modal-wrapper" *ngIf="showDataModal && showConfirmModal">
  <div class="confirmation-modal animate__animated animate__slideInDown">
    <div class="confirmation-modal-container">
      <div class="modal-head">
        <h4>Confirm Status Change</h4>
        <div class="confirm-modal-close-button" (click)="[showPage = '',showDataModal= false, showConfirmModal= false]">
          <x-circle-outline-icon></x-circle-outline-icon>
        </div>
      </div>
      <hr>
      <div style="min-height:90px;">
        Do you want to change the status from <strong>Active</strong> to <strong>On Hold</strong>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success">Yes, Change</button>
        <button class="btn btn-secondary" (click)="[showPage = '',showDataModal= false, showConfirmModal=false]">No,
          Cancel</button>
      </div>
    </div>
  </div>