import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-support',
  templateUrl: './add-form.component.html',
  styleUrls: ['./add-form.component.scss']
})
export class AddSupportComponent implements OnInit {

  //constructor() { }

  //ngOnInit(): void {
  //}

  showContextMenu: boolean = false;
  //@Input() showStats: boolean = PageHeaderComponent;
  public showPreloadData: boolean = false;
  public showDataModal: boolean = false;

  showStats: boolean = true;
  showRightMenu: boolean = false;
  showFilter: boolean = true;
  addressForm: FormGroup;
  supportForm: FormGroup;
  constructor(private fb: FormBuilder, private sb: FormBuilder) {
    this.addressForm = this.fb.group({
      addressList: this.fb.array([])
    });
    this.supportForm = this.sb.group({
      supportList: this.sb.array([])
    });
    this.addAddress();
    this.addSupport();
  }

  ngOnInit() { }

  get addresses() {
    return this.addressForm.get("addressList") as FormArray;
  }
  get supports() {
    return this.supportForm.get("supportList") as FormArray;
  }

  addAddress() {
    this.addresses.push(this.createAddress());
  }

  removeAddress(i: number) {
    this.addresses.removeAt(i);
  }

  addSupport() {
    this.supports.push(this.createSupport());
  }

  removeSupport(i: number) {
    this.supports.removeAt(i);
  }

  createAddress() {
    return this.fb.group({

    });
  }
  createSupport() {
    return this.sb.group({

    });
  }
}
