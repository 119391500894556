<div class="d-flex flex-column" style="min-width:calc(100vw - 180px);max-height:100vh; overflow-y: hidden;">
  <app-page-header>
    <div slot-breadcrumb>Dashboard</div>
    <div slot-heading>
      <div class="d-flex align-items-center">
        <a href="#" class="">Engineer's Dashboard</a>
        <div class="position-relative">
          <div class="d-flex align-items-center" style="cursor: pointer;" (click)="showRightMenu = !showRightMenu">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px" y="0px" viewBox="0 0 213.333 213.333" style="enable-background:new 0 0 213.333 213.333;"
              xml:space="preserve" width="9px" height="9px" class="mx-1">
              <g>
                <g>
                  <polygon points="0,53.333 106.667,160 213.333,53.333 		" />
                </g>
              </g>
            </svg>
            <!--<span class="ml-2">John Doe</span> -->
          </div>
          <div *ngIf="showRightMenu" class="position-absolute bg-white border"
            style="min-width:200px; z-index: 301;right: 0;margin-top: 10px;">
            <ul class="list-inline text-sm mb-0 py-1">
              <li class="px-3 py-2">
                <a href="#">Engineers Dashboard</a>
              </li>
              <li class="px-3 py-2"> <a href="#">Manager Dashboard</a></li>
              <li class="px-3 py-2"><a href="#">Sales Dashboard</a></li>
            </ul>
          </div>
        </div>
      </div>

    </div>
    <div slot-contextmenu>
      <li><a href="#">Edit Dashboard</a></li>
    </div>
  </app-page-header>
  <app-page-content>
    <!-- Dashboard Content Here -->
    <div class="d-flex flex-column">
      <div class="stat-row">
        <!--<div class="stat-card mx-1 d-flex align-items-center justify-content-between rounded"
          style="background-image: linear-gradient(to right top, #1be0da, #00ceeb, #00b8f7, #009ff8, #5882e8);">
          <div class="text-white">
            <p class="stat-data mb-1">17</p>
            <h6 class="stat-heading">Quotes</h6>
          </div>
          <div>
            <svg id="Layer_1" enable-background="new 0 0 512.002 512.002" height="512" viewBox="0 0 512.002 512.002"
              width="40px" height="40px" xmlns="http://www.w3.org/2000/svg" style="filter:brightness(0) invert(1)">
              <g>
                <g>
                  <path
                    d="m482.002 65.996h-452.002c-16.542 0-30 13.462-30 30.009l.001 327.312c-.007 8.935 4.893 16.796 12.787 20.516 3.095 1.458 6.392 2.172 9.69 2.172 5.436 0 10.876-1.941 15.414-5.694l41.313-34.166h131.792c5.522 0 10-4.477 10-10s-4.478-10-10-10h-135.391c-2.326 0-4.58.811-6.373 2.294l-44.087 36.461c-1.841 1.522-3.296 1.095-3.834.841-.884-.416-1.312-1.204-1.312-2.415v-327.321c0-5.519 4.486-10.009 10-10.009h452.002c5.514 0 10 4.49 10 10.009v280.131c0 5.519-4.486 10.009-10 10.009h-181.004c-5.522 0-10 4.477-10 10s4.478 10 10 10h181.004c16.542 0 30-13.462 30-30.009v-280.131c0-16.547-13.458-30.009-30-30.009z" />
                  <path
                    d="m210.533 146.071h-60.562c-16.645 0-30.187 13.542-30.187 30.187v60.561c0 16.645 13.542 30.187 30.187 30.187h25.129c1.956 4.813.533 12.149-4.094 20.164-6.179 10.701-4.783 23.438 3.473 31.694 4.765 4.765 10.965 7.194 17.542 7.194 5.033 0 10.286-1.422 15.284-4.308 33.418-19.294 33.416-60.121 33.415-84.514v-60.978c0-16.645-13.542-30.187-30.187-30.187zm10.187 91.166c.001 22.651.002 53.672-23.415 67.192-3.615 2.087-6.78 2.196-8.684.293-1.427-1.427-2.253-4.16-.295-7.552 10.596-18.354 10.222-36.012-1.002-47.236-1.876-1.875-4.419-2.929-7.071-2.929h-30.281c-5.617 0-10.187-4.57-10.187-10.187v-60.561c0-5.617 4.569-10.187 10.187-10.187h60.562c5.617 0 10.187 4.57 10.187 10.187v60.98z" />
                  <path
                    d="m326.597 267.006c1.956 4.813.533 12.149-4.094 20.164-6.179 10.701-4.783 23.438 3.473 31.694 4.765 4.765 10.965 7.193 17.542 7.193 5.032 0 10.285-1.422 15.283-4.308 33.419-19.294 33.417-60.121 33.416-84.514v-60.978c0-16.645-13.542-30.187-30.187-30.187h-60.562c-16.645 0-30.187 13.542-30.187 30.187v60.561c0 16.645 13.542 30.187 30.187 30.187h25.129zm-35.315-30.187v-60.561c0-5.617 4.569-10.187 10.187-10.187h60.562c5.617 0 10.187 4.57 10.187 10.187v60.979c.001 22.65.002 53.672-23.416 67.192-3.617 2.087-6.78 2.195-8.683.293-1.427-1.427-2.253-4.16-.295-7.552 10.597-18.354 10.221-36.013-1.004-47.236-1.875-1.875-4.418-2.929-7.07-2.929h-30.28c-5.618.001-10.188-4.569-10.188-10.186z" />
                  <path
                    d="m256.001 386.146h-.007c-5.522 0-9.996 4.477-9.996 10s4.48 10 10.003 10 10-4.477 10-10-4.478-10-10-10z" />
                </g>
              </g>
            </svg>
          </div>
        </div>-->
        <div class="stat-card mx-1 d-flex align-items-center justify-content-between rounded border-0"
          style="background-image: linear-gradient(to right top, #1be0da, #00ceeb, #00b8f7, #009ff8, #5882e8);">
          <div class="text-white mb-2">
            <p class="stat-data mb-2">500</p>
            <h6 class="stat-heading">Jobs</h6>
          </div>
          <div>
            <svg id="light" enable-background="new 0 0 24 24" height="35px" viewBox="0 0 24 24" width="35px"
              style="filter: brightness(0) invert(1);" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path
                  d="m21.5 22h-19c-1.4 0-2.5-1.1-2.5-2.5v-12c0-1.4 1.1-2.5 2.5-2.5h19c1.4 0 2.5 1.1 2.5 2.5v12c0 1.4-1.1 2.5-2.5 2.5zm-19-16c-.8 0-1.5.7-1.5 1.5v12c0 .8.7 1.5 1.5 1.5h19c.8 0 1.5-.7 1.5-1.5v-12c0-.8-.7-1.5-1.5-1.5z" />
              </g>
              <g>
                <path
                  d="m15.5 6c-.3 0-.5-.2-.5-.5v-2c0-.3-.2-.5-.5-.5h-5c-.3 0-.5.2-.5.5v2c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-2c0-.8.7-1.5 1.5-1.5h5c.8 0 1.5.7 1.5 1.5v2c0 .3-.2.5-.5.5z" />
              </g>
              <g>
                <path
                  d="m12 14c-.1 0-.1 0-.2 0l-11.5-4c-.2-.1-.4-.4-.3-.7.1-.2.4-.4.7-.3l11.3 4 11.3-4c.3-.1.5 0 .6.3s0 .5-.3.6l-11.5 4c0 .1 0 .1-.1.1z" />
              </g>
            </svg>
          </div>
        </div>
        <div class="stat-card mx-1 d-flex align-items-center justify-content-between rounded border-0"
          style="background-image: linear-gradient(to right top, #f5509e, #fa5793, #fd6088, #fe6980, #fe7378);">
          <div class="text-white mb-2">
            <p class="stat-data mb-2">70</p>
            <h6 class="stat-heading">Invoice</h6>
          </div>
          <div>
            <svg id="Layer_1" enable-background="new 0 0 480 480" height="35px" viewBox="0 0 480 480" width="35px"
              style="filter: brightness(0) invert(1);" xmlns="http://www.w3.org/2000/svg">
              <path
                d="m215 164c0 57.897 47.103 105 105 105s105-47.103 105-105-47.103-105-105-105-105 47.103-105 105zm194 0c0 49.075-39.925 89-89 89s-89-39.925-89-89 39.925-89 89-89 89 39.925 89 89zm-89-54c4.418 0 8 3.582 8 8v3.376c9.311 3.303 16 12.195 16 22.624 0 4.418-3.582 8-8 8s-8-3.582-8-8c0-4.411-3.589-8-8-8s-8 3.589-8 8v3.237c0 3.518 2.256 6.586 5.614 7.636l9.544 2.982c10.074 3.149 16.842 12.355 16.842 22.908v3.237c0 11.519-8.159 21.166-19 23.473v2.527c0 4.418-3.582 8-8 8s-8-3.582-8-8v-4.68c-7.714-3.996-13-12.05-13-21.32 0-4.418 3.582-8 8-8s8 3.582 8 8c0 4.411 3.589 8 8 8s8-3.589 8-8v-3.237c0-3.518-2.256-6.586-5.614-7.636l-9.544-2.982c-10.074-3.149-16.842-12.355-16.842-22.908v-3.237c0-10.429 6.689-19.321 16-22.624v-3.376c0-4.418 3.582-8 8-8zm130 212v102c0 30.879-25.122 56-56 56h-308c-30.878 0-56-25.121-56-56v-392c0-17.645 14.355-32 32-32h260c17.645 0 32 14.355 32 32 0 4.418-3.582 8-8 8s-8-3.582-8-8c0-8.822-7.178-16-16-16h-260c-8.822 0-16 7.178-16 16v392c0 22.056 17.944 40 40 40h268.862c-10.395-10.172-16.862-24.342-16.862-40v-125c0-4.418 3.582-8 8-8s8 3.582 8 8v125c0 22.056 17.944 40 40 40s40-17.944 40-40v-102c0-8.822-7.178-16-16-16h-34c-4.418 0-8-3.582-8-8s3.582-8 8-8h34c17.645 0 32 14.355 32 32zm-350-107c0-4.418 3.582-8 8-8h68c4.418 0 8 3.582 8 8s-3.582 8-8 8h-68c-4.418 0-8-3.582-8-8zm0-90c0-4.418 3.582-8 8-8h68c4.418 0 8 3.582 8 8s-3.582 8-8 8h-68c-4.418 0-8-3.582-8-8zm184 180c0 4.418-3.582 8-8 8h-168c-4.418 0-8-3.582-8-8s3.582-8 8-8h168c4.418 0 8 3.582 8 8zm0 84c0 4.418-3.582 8-8 8h-168c-4.418 0-8-3.582-8-8s3.582-8 8-8h168c4.418 0 8 3.582 8 8z" />
            </svg>
          </div>
        </div>
        <div class="stat-card mx-1 d-flex align-items-center justify-content-between rounded border-0"
          style="background-image: linear-gradient(to right top, #ffda3f, #ffc43f, #ffaf45, #ff9a4e, #ff8757);">
          <div class="text-white mb-2">
            <p class="stat-data mb-2">1000</p>
            <h6 class="stat-heading">Purchase Orders</h6>
          </div>
          <div>
            <svg id="_x31__px" height="512" viewBox="0 0 24 24" width="35px" height="35px"
              style="filter: brightness(0) invert(1);" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path
                  d="m11 24c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3c-.552 0-1 .449-1 1s.448 1 1 1 1-.449 1-1-.448-1-1-1z" />
              </g>
              <g>
                <path
                  d="m18 24c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3c-.552 0-1 .449-1 1s.448 1 1 1 1-.449 1-1-.448-1-1-1z" />
              </g>
              <g>
                <path d="m9.5 9h-3.75c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3.75c.276 0 .5.224.5.5s-.224.5-.5.5z" />
              </g>
              <g>
                <path
                  d="m19.1 19h-9.09c-1.115 0-2.103-.747-2.401-1.816l-3.179-11.096c-.18-.642-.771-1.088-1.44-1.088h-2.49c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h2.49c1.115 0 2.103.747 2.401 1.816l3.179 11.096c.18.642.771 1.088 1.44 1.088h9.09c.626 0 1.193-.399 1.411-.992l.909-2.489c.095-.259.382-.392.642-.298.259.095.393.382.298.641l-.909 2.49c-.363.986-1.307 1.648-2.351 1.648z" />
              </g>
              <g>
                <path
                  d="m18 14c-.066 0-.132-.013-.194-.04-.238-.099-5.806-2.506-5.806-7.424v-4.179c0-.214.137-.405.34-.474l5.5-1.857c.104-.035.217-.035.32 0l5.5 1.857c.203.069.34.26.34.474v4.179c0 4.918-5.568 7.325-5.806 7.425-.062.026-.128.039-.194.039zm-5-11.284v3.82c0 3.785 4.055 5.958 5 6.414.944-.457 5-2.638 5-6.414v-3.82l-5-1.688z" />
              </g>
              <g>
                <path
                  d="m17.5 9c-.009 0-.019 0-.027-.001-.143-.008-.274-.076-.363-.187l-2-2.5c-.172-.216-.138-.53.078-.703.218-.172.531-.137.703.078l1.65 2.064 3.105-3.105c.195-.195.512-.195.707 0s.195.512 0 .707l-3.5 3.5c-.093.095-.221.147-.353.147z" />
              </g>
            </svg>
          </div>
        </div>
        <div class="stat-card mx-1 d-flex align-items-center justify-content-between rounded border-0"
          style="background-image: linear-gradient(to right top, #e06cdc, #d564e3, #c65deb, #b557f4, #9e54fd);">
          <div class="text-white mb-2">
            <p class="stat-data mb-2">20</p>
            <h6 class="stat-heading">Supports</h6>
          </div>
          <div>
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px" y="0px" viewBox="0 0 422.139 422.139" style="enable-background:new 0 0 422.139 422.139;"
              xml:space="preserve" width="35px" height="35px" style="filter: brightness(0) invert(1);">
              <g>
                <g>
                  <path d="M363.631,174.498h-1.045v-25.6C362.586,66.664,295.923,0,213.688,0S64.79,66.664,64.79,148.898v25.6h-6.269
			c-22.988,0-40.751,20.375-40.751,43.886v65.306c-0.579,22.787,17.425,41.729,40.212,42.308c0.18,0.005,0.359,0.008,0.539,0.01
			h38.661c5.476-0.257,9.707-4.906,9.449-10.382c-0.009-0.197-0.024-0.394-0.045-0.59v-128c0-6.269-3.657-12.539-9.404-12.539
			H85.688v-25.6c0-70.692,57.308-128,128-128s128,57.308,128,128v25.6h-11.494c-5.747,0-9.404,6.269-9.404,12.539v128
			c-0.583,5.451,3.363,10.343,8.814,10.926c0.196,0.021,0.393,0.036,0.59,0.045h12.016l-1.045,1.567
			c-15.677,20.835-40.277,33.038-66.351,32.914c-5.708-27.989-33.026-46.052-61.015-40.343
			c-23.935,4.881-41.192,25.843-41.385,50.27c0.286,28.65,23.594,51.724,52.245,51.722c14.183-0.23,27.702-6.05,37.616-16.196
			c6.689-6.85,11.072-15.617,12.539-25.078c32.652,0.124,63.445-15.176,83.069-41.273l9.927-14.629
			c22.465-1.567,36.571-15.673,36.571-36.049v-65.306C404.382,201.143,387.664,174.498,363.631,174.498z M85.688,305.11H58.521
			c-11.25-0.274-20.148-9.615-19.874-20.865c0.005-0.185,0.012-0.37,0.021-0.556v-65.306c0-12.016,8.359-22.988,19.853-22.988
			h27.167V305.11z M247.125,391.314c-5.79,6.278-13.925,9.873-22.465,9.927c-16.998-0.27-30.792-13.834-31.347-30.825
			c-0.007-17.024,13.788-30.83,30.812-30.837c17.024-0.007,30.83,13.788,30.837,30.812c0,0.008,0,0.017,0,0.025
			C255.397,378.173,252.553,385.756,247.125,391.314z M383.484,288.914c0,14.106-13.584,16.196-19.853,16.196h-21.943V195.396
			h21.943c11.494,0,19.853,16.196,19.853,28.212V288.914z" />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div class="stat-card mx-1 d-flex align-items-center justify-content-between rounded border-0"
          style="background-image: linear-gradient(to right top, #41e197, #26d7a5, #17cdae, #25c2b3, #3bb6b4);">
          <div class="text-white mb-2">
            <p class="stat-data mb-2">30:20</p>
            <h6 class="stat-heading">Hour This Week</h6>
          </div>
          <div>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 368 368"
              style="enable-background:new 0 0 368 368;filter: brightness(0) invert(1);" xml:space="preserve"
              width="35px" height="35px">
              <g>
                <g>
                  <g>
                    <path d="M184,60c4.4,0,8-3.6,8-8v-4c0-4.4-3.6-8-8-8c-4.4,0-8,3.6-8,8v4C176,56.4,179.6,60,184,60z" />
                    <path
                      d="M184,308c-4.4,0-8,3.6-8,8v4c0,4.4,3.6,8,8,8c4.4,0,8-3.6,8-8v-4C192,311.6,188.4,308,184,308z" />
                    <path d="M52,176h-4c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h4c4.4,0,8-3.6,8-8C60,179.6,56.4,176,52,176z" />
                    <path
                      d="M320,176h-4c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h4c4.4,0,8-3.6,8-8C328,179.6,324.4,176,320,176z" />
                    <path d="M93.6,82.4c-3.2-3.2-8-3.2-11.2,0c-3.2,3.2-3.2,8,0,11.2l2.8,2.8c1.6,1.6,3.6,2.4,5.6,2.4s4-0.8,5.6-2.4
				c3.2-3.2,3.2-8,0-11.2L93.6,82.4z" />
                    <path d="M85.2,271.6l-2.8,2.8c-3.2,3.2-3.2,8,0,11.2C84,287.2,86,288,88,288s4-0.8,5.6-2.4l2.8-2.8c3.2-3.2,3.2-8,0-11.2
				S88.4,268.4,85.2,271.6z" />
                    <path d="M274.4,82.4l-2.8,2.8c-3.2,3.2-3.2,8,0,11.2c1.6,1.6,3.6,2.4,5.6,2.4s4-0.8,5.6-2.4l2.8-2.8c3.2-3.2,3.2-8,0-11.2
				C282.4,79.2,277.6,79.2,274.4,82.4z" />
                    <path d="M192,180.8V108c0-4.4-3.6-8-8-8c-4.4,0-8,3.6-8,8v76c0,2,0.8,4,2.4,5.6l87.6,87.6c1.6,1.6,3.6,2.4,5.6,2.4s4-0.8,5.6-2.4
				c3.2-3.2,3.2-8,0-11.2L192,180.8z" />
                    <path d="M184,0C82.4,0,0,82.4,0,184s82.4,184,184,184s184-82.4,184-184S285.6,0,184,0z M184,352c-92.8,0-168-75.2-168-168
				S91.2,16,184,16s168,75.2,168,168S276.8,352,184,352z" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </app-page-content>
</div>
