<div class="d-flex flex-column" style="min-width:calc(100vw - 180px);max-height:100vh; overflow-y: hidden;">
  <app-page-header>
    <div slot-breadcrumb>Contacts</div>
    <div slot-heading>
      <div class="d-flex align-items-center">
        <a href="#" class="">Client Manager</a>
        <div class="position-relative">
          <div class="d-flex align-items-center" style="cursor: pointer;" (click)="showRightMenu = !showRightMenu">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px" y="0px" viewBox="0 0 213.333 213.333" style="enable-background:new 0 0 213.333 213.333;"
              xml:space="preserve" width="9px" height="9px" class="mx-1">
              <g>
                <g>
                  <polygon points="0,53.333 106.667,160 213.333,53.333 		" />
                </g>
              </g>
            </svg>
            <!--<span class="ml-2">John Doe</span> -->
          </div>
          <div *ngIf="showRightMenu" class="position-absolute bg-white border"
            style="min-width:200px; z-index: 301;right: 0;margin-top: 10px;">
            <ul class="list-inline text-sm mb-0 py-1 nav nav-tabs custom-nav-tabs" role="tablist">
              <li class="px-3 py-1">
                <a class="nav-link active px-0 py-1" data-toggle="tab" href="#tabs-1" role="tab">Client Manager</a>
              </li>
              <li class="px-3 py-1"> <a class="nav-link px-0 py-1" data-toggle="tab" href="#tabs-2" role="tab">Supplier
                  Manager</a></li>
              <li class="px-3 py-1"><a class="nav-link px-0 py-1" data-toggle="tab" href="#tabs-3" role="tab">Contact
                  Manager</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div slot-contextmenu>
      <li><a href="#">Add Contact</a></li>
      <li><a href="#">Export Contact</a></li>
    </div>
  </app-page-header>
  <!-- <app-page-content>
    Contactlist Table Here
  </app-page-content> -->
  <app-page-content>
    <div class="m-0 p-0" style="overflow-y: auto;">
      <div class="my-2">
        <!-- <ul class="nav nav-tabs custom-nav-tabs text-xs" role="tablist">
          <li class="nav-item px-2">
            <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Client Manager</a>
          </li>
          <li class="nav-item px-2">
            <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Supplier Manager</a>
          </li>
          <li class="nav-item px-2">
            <a class="nav-link" data-toggle="tab" href="#tabs-3" role="tab">Contact Manager</a>
          </li>
        </ul>-->
        <!-- Tab panes -->
        <div class="tab-content px-3 py-2 text-sm">
          <div class="tab-pane active" id="tabs-1" role="tabpanel">
            <div class="d-flex align-items-center justify-content-end mb-3">
              <div class="mx-2">
                <select class="form-control text-sm">
                  <option>Clients</option>
                  <option>Archive</option>
                </select>
              </div>
              <div>
                <input _ngcontent-otx-c476="" type="search" placeholder="Search" class="form-control text-sm">
              </div>
            </div>
            <table class="table table-striped text-xs">
              <thead>
                <tr class="bg-light">
                  <th width="30%">Client Name</th>
                  <th width="30%">Postal Address</th>
                  <th width="20%">Phone</th>
                  <th width="30%">Fax</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><a href="#">3M Australia (TELSTRA)</a></td>
                  <td>-</td>
                  <td>+91 98765 43210</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td><a href="#">Test Client 2</a></td>
                  <td>-</td>
                  <td>+91 98765 43220</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td><a href="#">Test Client 3</a></td>
                  <td>-</td>
                  <td>+91 98765 43020</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tab-pane" id="tabs-2" role="tabpanel">
            <div class="d-flex align-items-center justify-content-end mb-3">
              <div class="mx-2">
                <select class="form-control text-sm">
                  <option>Suppliers</option>
                  <option>Archive</option>
                </select>
              </div>
              <div>
                <input _ngcontent-otx-c476="" type="search" placeholder="Search" class="form-control text-sm">
              </div>
            </div>
            <table class="table table-striped text-xs">
              <thead>
                <tr class="bg-light">
                  <th width="30%">Supplier Name</th>
                  <th width="30%">Address</th>
                  <th width="20%">Phone</th>
                  <th width="30%">Fax</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><a href="#">3M Australia (TELSTRA)</a></td>
                  <td>-</td>
                  <td>+91 98765 43210</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td><a href="#">Test Client 2</a></td>
                  <td>-</td>
                  <td>+91 98765 43220</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td><a href="#">Test Client 3</a></td>
                  <td>-</td>
                  <td>+91 98765 43020</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tab-pane" id="tabs-3" role="tabpanel">
            <div class="d-flex align-items-center justify-content-end mb-3">
              <div class="mx-2">
                <select class="form-control text-sm">
                  <option>Clients</option>
                  <option>Supplier</option>
                </select>
              </div>
              <div>
                <input _ngcontent-otx-c476="" type="search" placeholder="Search" class="form-control text-sm">
              </div>
            </div>
            <table class="table table-striped text-xs">
              <thead>
                <tr class="bg-light">
                  <th width="30%">Contact Name</th>
                  <th width="30%">Client</th>
                  <th width="20%">Phone</th>
                  <th width="10%">Mobile</th>
                  <th width="10%">Email</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><a href="#">Grant Turvey</a></td>
                  <td>3M Australia (TELSTRA)</td>
                  <td>+91 98765 43210</td>
                  <td>04893 44564</td>
                  <td>grant@domain.com</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </app-page-content>
</div>
