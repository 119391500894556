import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgHeroiconsModule } from "@dimaslz/ng-heroicons";
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlankLayoutComponent } from './blank-layout/blank-layout.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ContactsComponent } from './contacts/contacts.component';
import { QuotesComponent } from './quotes/quotes.component';
import { JobsComponent } from './jobs/jobs.component';
import { SupportComponent } from './support/support.component';
import { TimesheetsComponent } from './timesheets/timesheets.component';
import { FinancialComponent } from './financial/financial.component';
import { AdminComponent } from './admin/admin.component';
import { JoblistComponent } from './joblist/joblist.component';

import { JobsdetailComponent } from './jobsdetail/jobsdetail.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageContentComponent } from './page-content/page-content.component';
import { SidebarNavigationComponent } from './sidebar-navigation/sidebar-navigation.component';
import { DataTablesModule } from 'angular-datatables';
import { jqxDataTableModule } from 'jqwidgets-ng/jqxdatatable';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TaskListComponent } from './task-list/task-list.component';
import { AddSupportComponent } from './support/add-form/add-form.component';
import { EditSupportComponent } from './support/edit-form/edit-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    BlankLayoutComponent,
    MainLayoutComponent,
    ContactsComponent,
    QuotesComponent,
    JobsComponent,
    SupportComponent,
    TimesheetsComponent,
    FinancialComponent,
    AdminComponent,
    JoblistComponent,
    JobsdetailComponent,
    PageHeaderComponent,
    PageContentComponent,
    SidebarNavigationComponent,
    TaskListComponent,
    AddSupportComponent,
    EditSupportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgHeroiconsModule,
    DataTablesModule,
    //  NgxDatatableModule
    jqxDataTableModule,
    //  jqxTabsModule
    // GridModule,
    // PDFModule,
    // ExcelModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    [SweetAlert2Module.forRoot()],
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA], // add this line
  entryComponents: [
    TaskListComponent
  ]
})
export class AppModule { }
