import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobsdetail.component.html',
  styleUrls: ['./jobsdetail.component.scss']
})
export class JobsdetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  showContextMenu: boolean = false;
  showStats: boolean = true;


}
