<table class="table table-sm table-striped text-sm">
  <thead>
    <th>#</th>
    <th>Task Name</th>
    <th>Status</th>
    <th>Assigned To</th>
    <th>Due Date</th>
  </thead>
  <tbody>
    <tr *ngFor="let task of tasks">
      <td>{{ task.id }}</td>
      <td>{{ task.name }}</td>
      <td>{{ task.status }}</td>
      <td>{{ task.assignedTo }}</td>
      <td>{{ task.dueDate }}</td>
    </tr>
  </tbody>
</table>
