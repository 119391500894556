<div class="d-flex flex-column" style="min-width:calc(100vw - 180px);max-height:100vh; overflow-y: hidden;">
  <app-page-header>
    <div slot-breadcrumb>Annuity Contract | Contract List</div>
    <div slot-heading>
      <div class="d-flex align-items-center">Active Contracts
        <div class="position-relative">
          <div class="d-flex align-items-center" style="cursor: pointer;" (click)="showRightMenu = !showRightMenu">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px" y="0px" viewBox="0 0 213.333 213.333" style="enable-background:new 0 0 213.333 213.333;"
              xml:space="preserve" width="9px" height="9px" class="mx-1">
              <g>
                <g>
                  <polygon points="0,53.333 106.667,160 213.333,53.333 		" />
                </g>
              </g>
            </svg>
            <!--<span class="ml-2">John Doe</span> -->
          </div>
          <div *ngIf="showRightMenu" class="position-absolute bg-white border"
            style="min-width:170px; z-index: 301;right: 0;margin-top: 10px;">
            <ul class="list-inline text-sm mb-0 py-1">
              <li class="pl-1 pr-2 py-2 d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="13px" height="13px" x="0" y="0"
                  viewBox="0 0 408.576 408.576" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                  <g>
                    <g xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path
                          d="M204.288,0C91.648,0,0,91.648,0,204.288s91.648,204.288,204.288,204.288s204.288-91.648,204.288-204.288    S316.928,0,204.288,0z M318.464,150.528l-130.56,129.536c-7.68,7.68-19.968,8.192-28.16,0.512L90.624,217.6    c-8.192-7.68-8.704-20.48-1.536-28.672c7.68-8.192,20.48-8.704,28.672-1.024l54.784,50.176L289.28,121.344    c8.192-8.192,20.992-8.192,29.184,0C326.656,129.536,326.656,142.336,318.464,150.528z"
                          fill="#34d399" data-original="#000000" class="" />
                      </g>
                    </g>

                  </g>
                </svg>
                <a href="#" class="mx-2">Active Contracts</a>
              </li>
              <li class="px-3 py-2"><a href="#">Archived Contracts</a></li>
              <li class="px-3 py-2"><a href="#">Quoted Contracts</a></li>
              <hr style="margin:0 auto">
              <li class="px-3 py-2"> <a href="#">My Contracts</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div slot-options>
      <select name="staffs" id="staffs" form="staffs" class="custom-select">
        <option value="1">Karthick Kumar</option>
        <option value="2">Muthu Kumar</option>
        <option value="3">Senthil Kumar</option>
      </select>
    </div>
    <div slot-contextmenu>
      <li><a href="#" routerLink="/support/add">Create New Contract</a></li>
      <li><a href="#">Export Data</a></li>
    </div>
    <div slot-searchinput>
      <input class="form-control" type="search" placeholder="Search Contract">
    </div>
    <div slot-searchfilter>
      <div class="btn border p-1 mx-2 rounded" (click)="showStats = !showStats">
        <chart-pie-outline-icon></chart-pie-outline-icon>
      </div>
    </div>
  </app-page-header>
  <app-page-content>
    <!-- Support Contract Comes Here -->
    <!-- start -->
    <div *ngIf="showStats" class="d-flex flex-column border-bottom-light stat-section">
      <div class="stat-row">
        <div class="stat-card">
          <p class="stat-data">2</p>
          <h6 class="stat-heading">Active Contracts</h6>
        </div>
        <div class="stat-card">
          <p class="stat-data">$ 30000</p>
          <h6 class="stat-heading">Total Contract Value</h6>
        </div>
        <div class="stat-card">
          <p class="stat-data">$ 20000</p>
          <h6 class="stat-heading"> Total External Cost </h6>
        </div>
        <div class="stat-card">
          <p class="stat-data">$ 30000</p>
          <h6 class="stat-heading">Total TeleApps HD</h6>
        </div>
        <div class="stat-card">
          <p class="stat-data">$ 10000</p>
          <h6 class="stat-heading">Total TeleApps Prepaid</h6>
        </div>
        <div class="stat-card">
          <p class="stat-data">$ 80000</p>
          <h6 class="stat-heading">Total Net</h6>
        </div>

      </div>
      <!--bindings={
        "ng-reflect-ng-if": "true"
      }-->
    </div>
    <!-- end -->
    <!-- Table start -->
    <div class="d-flex flex-column" x-data="{showContents: false,showContract: false,showSupport:false}">
      <div class="w-main d-flex flex-column bg-white ">

      </div>
      <div class="mx-3 flex-fill my-4"
        style="max-height:calc(100vh - 220px);max-width:calc(100vw - 85px); margin-top: 5px; overflow-x: scroll;">
        <!-- min-height:calc(100vh -200px);width:calc(100vw - 120px); -->
        <table class="table text-sm dataTable"
          style="overflow-x: scroll; height: 400px;overflow-y: auto;width:max-content;">
          <!-- width:2000px !important; -->
          <thead>
            <!--<th width="18px">
              <div class=""><input type="checkbox"></div>
            </th> -->

            <th width="150px">
              <div class="d-flex justify-content-between">
                <div class="flex-grow-1">Contract Number</div>
              </div>
            </th>
            <th width="200px">
              Customer
            </th>
            <th width="400px">
              Contract name
            </th>
            <th width="90px">
              Start date
            </th>
            <th width="90px">
              End date
            </th>
            <th width="80px">
              Duration
            </th>
            <th width="100px">
              Contract value
            </th>
            <th width="110px">
              External costs
            </th>
            <th width="90px">
              Internal costs
            </th>
            <th width="100px">
              Margin
            </th>

            <th></th>
          </thead>
          <tbody>
            <tr>
              <td>
                <a href="#">1SUP001</a>
                <clipboard-list-outline-icon (click)="[showDataModal = true, showPage = 'notes']" title="Notes">
                </clipboard-list-outline-icon>
              </td>
              <td>
                Gegosoft
              </td>
              <td>
                <!-- <clipboard-list-outline-icon (mouseenter)="[showData = job.id, showLastNote = true]"
                  (mouseleave)="[showData = job.id, showLastNote = false]"
                  (click)="[showDataModal = true, showPage = 'notes' , showOptionsMenu = !showOptionsMenu,  pageData= job.notes]">
                </clipboard-list-outline-icon>-->
                <div class="d-flex align-items-center">
                  <div class="position-relative ">

                    <document-text-outline-icon x-on:mouseover="showContract = true"
                      x-on:mouseleave="showContract = false"
                      (click)="[showDataModal = true,showPage = 'contract-modal']" title="Contract">
                    </document-text-outline-icon>
                    <div x-show="showContract" class="position-absolute custom-dropdown-menu text-xs"
                      style="min-width: 550px;">
                      <p class="mb-1 text-xs font-bold">Contract Terms : 5 days</p>
                      <table class="table text-xs support-table border mb-2">
                        <thead>
                          <tr>
                            <th>Sun</th>
                            <th>Mon</th>
                            <th>Tue</th>
                            <th>Wed</th>
                            <th>Thu</th>
                            <th>Fri</th>
                            <th>Sat</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>-</td>
                            <td><img src="assets/icons/checked.svg" width="14" height="14"></td>
                            <td><img src="assets/icons/checked.svg" width="14" height="14"></td>
                            <td><img src="assets/icons/checked.svg" width="14" height="14"></td>
                            <td><img src="assets/icons/checked.svg" width="14" height="14"></td>
                            <td><img src="assets/icons/checked.svg" width="14" height="14"></td>
                            <td>-</td>
                          </tr>
                          <tr>
                            <td>-</td>
                            <td>8 AM - 8 PM</td>
                            <td>8 AM - 1 PM</td>
                            <td>8 AM - 6 PM</td>
                            <td>8 AM - 12 PM</td>
                            <td>8 AM - 9 AM</td>
                            <td>-</td>
                          </tr>
                        </tbody>

                      </table>
                      <p class="mb-1 text-xs font-bold">Service Level Agreements</p>
                      <table class="table text-xs support-table border w-100 mb-1">
                        <thead>
                          <tr>
                            <th style="color: #e53e3e;font-weight: 600;">Critical</th>
                            <th style="color:#f5a522;font-weight: 600;">High</th>
                            <th style="color:#818cf8;font-weight: 600;">Medium</th>
                            <th style="color:#34d399;font-weight: 600;">Low</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>2 hrs</td>
                            <td>6 hrs</td>
                            <td>12 hrs</td>
                            <td>24 hrs</td>
                          </tr>

                        </tbody>

                      </table>
                    </div>
                    <shopping-bag-outline-icon x-on:mouseover="showSupport = true" x-on:mouseleave="showSupport = false"
                      (click)="[showDataModal = true,showPage = 'supported-products']" title="Supported Product">
                    </shopping-bag-outline-icon>


                    <div x-show="showSupport" class="position-absolute custom-dropdown-menu text-xs"
                      style="min-width: 680px;">
                      <p class="mb-1 text-xs font-bold">Supported Products</p>
                      <table class="table text-xs support-table border w-100 mb-1">
                        <thead>
                          <tr>
                            <th width="15%">Vendor</th>
                            <th width="20%">Product name</th>
                            <th width="20%">Product version</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Avaya</td>
                            <td>Some text here</td>
                            <td>Some text here</td>
                            <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                          </tr>
                          <tr>
                            <td>Teleapps</td>
                            <td>Some text here</td>
                            <td>Some text here</td>
                            <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                          </tr>
                        </tbody>

                      </table>
                    </div>
                    <document-report-outline-icon (click)="[showDataModal = true,showPage = 'supported-contents']"
                      title="Supported Contents">
                    </document-report-outline-icon>
                  </div>
                  <div class="position-relative">
                    <div x-on:mouseover="showContents = true" x-on:mouseleave="showContents = false" class="px-2">
                      Voice Support for
                      20
                      Member Team</div>
                    <div x-show="showContents" class="position-absolute custom-dropdown-menu text-xs"
                      style="width: max-content;max-width: 500px;">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                      the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                      type and scrambled it to make a type specimen book. It has survived not only five centuries, but
                      also the leap into electronic typesetting, remaining essentially unchanged.
                    </div>
                  </div>

                </div>
              </td>
              <td> 16-Feb-2021</td>
              <td> 22-Feb-2021</td>
              <td>12 Months </td>
              <td>$ a,aaa,aaa.aa</td>
              <td>$ b,bbb,bbb.bb</td>
              <td>$ ccc,ccc.cc</td>
              <td>$ ddd,ddd.dd</td>

              <td>
                <div class="d-flex">
                  <a href="#" routerLink="/support/edit" class="edit-btn px-3 py-1 rounded-pill text-xs">Edit</a>
                  <button class="renew-btn mx-1 text-white" (click)='renewContract()'>Renew</button>
                  <button class="end-btn mx-1 text-white" (click)='endContract()'>End</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <a href="#">1SUP002</a>
                <clipboard-list-outline-icon (click)="[showDataModal = true, showPage = 'notes']" title="Notes">
                </clipboard-list-outline-icon>
              </td>
              <td>
                Microsoft
              </td>
              <td>
                <div class="position-relative d-flex align-items-center">
                  <div>
                    <document-text-outline-icon title="Contract"></document-text-outline-icon>
                    <shopping-bag-outline-icon title="Supported Product"></shopping-bag-outline-icon>
                    <document-report-outline-icon title="Supported Contents"></document-report-outline-icon>
                  </div>
                  <span class="px-2">Microsoft Team Support for 100 Users</span>
                </div>
              </td>
              <td> 16-Feb-2021</td>
              <td> 22-Feb-2021</td>
              <td>6 Months</td>
              <td>$ a,aaa,aaa.aa</td>
              <td>$ b,bbb,bbb.bb</td>
              <td>$ ccc,ccc.cc</td>
              <td>$ ddd,ddd.dd</td>

              <td>
                <div class="d-flex">
                  <a href="#" routerLink="/support/edit" class="edit-btn px-3 py-1 rounded-pill text-xs">Edit</a>
                  <button class="renew-btn mx-1 text-white">Renew</button>
                  <button class="end-btn mx-1 text-white">End</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <a href="#">1SUP003</a>
                <clipboard-list-outline-icon (click)="[showDataModal = true, showPage = 'notes']" title="Notes">
                </clipboard-list-outline-icon>
              </td>
              <td>
                Google
              </td>
              <td>
                <div class="position-relative d-flex align-items-center">
                  <div>
                    <document-text-outline-icon title="Contract"></document-text-outline-icon>
                    <shopping-bag-outline-icon title="Supported Product"></shopping-bag-outline-icon>
                    <document-report-outline-icon title="Supported Contents"></document-report-outline-icon>
                  </div>
                  <span class="px-2">Sparza CX Tool for 1000 Users</span>
                </div>
              </td>
              <td> 16-Feb-2021</td>
              <td> 22-Feb-2021</td>
              <td>2 Years</td>
              <td>$ a,aaa,aaa.aa</td>
              <td>$ b,bbb,bbb.bb</td>
              <td>$ ccc,ccc.cc</td>
              <td>$ ddd,ddd.dd</td>

              <td>
                <div class="d-flex">
                  <a href="#" routerLink="/support/edit" class="edit-btn px-3 py-1 rounded-pill text-xs">Edit</a>
                  <button class="renew-btn mx-1 text-white">Renew</button>
                  <button class="end-btn mx-1 text-white">End</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <a href="#">1SUP004</a>
                <clipboard-list-outline-icon (click)="[showDataModal = true, showPage = 'notes']" title="Notes">
                </clipboard-list-outline-icon>
              </td>
              <td>
                Yahoo
              </td>
              <td>
                <div class="position-relative d-flex align-items-center">
                  <div>
                    <document-text-outline-icon title="Contract"></document-text-outline-icon>
                    <shopping-bag-outline-icon title="Supported Product"></shopping-bag-outline-icon>
                    <document-report-outline-icon title="Supported Contents"></document-report-outline-icon>
                  </div>
                  <span class="px-2">Wifi & Firewall for 5 Offices</span>
                </div>
              </td>
              <td> 16-Feb-2021</td>
              <td> 22-Feb-2021</td>
              <td>8 Months</td>
              <td>$ a,aaa,aaa.aa</td>
              <td>$ b,bbb,bbb.bb</td>
              <td>$ ccc,ccc.cc</td>
              <td>$ ddd,ddd.dd</td>
              <td>
                <div class="d-flex">
                  <a href="#" routerLink="/support/edit" class="edit-btn px-3 py-1 rounded-pill text-xs">Edit</a>
                  <button class="renew-btn mx-1 text-white">Renew</button>
                  <button class="end-btn mx-1 text-white">End</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <a href="#">1SUP005</a>
                <clipboard-list-outline-icon (click)="[showDataModal = true, showPage = 'notes']" title="Notes">
                </clipboard-list-outline-icon>
              </td>
              <td>
                Gegosoft
              </td>
              <td>
                <div class="position-relative d-flex align-items-center">
                  <div>
                    <document-text-outline-icon title="Contract"></document-text-outline-icon>
                    <shopping-bag-outline-icon title="Supported Product"></shopping-bag-outline-icon>
                    <document-report-outline-icon title="Supported Contents"></document-report-outline-icon>
                  </div>
                  <span class="px-2">Voice support for 50 users</span>
                </div>
              </td>
              <td> 16-Feb-2021</td>
              <td> 22-Feb-2021</td>
              <td>8 Months</td>
              <td>$ a,aaa,aaa.aa</td>
              <td>$ b,bbb,bbb.bb</td>
              <td>$ ccc,ccc.cc</td>
              <td>$ ddd,ddd.dd</td>

              <td>
                <div class="d-flex">
                  <a href="#" routerLink="/support/edit" class="edit-btn px-3 py-1 rounded-pill text-xs">Edit</a>
                  <button class="renew-btn mx-1 text-white">Renew</button>
                  <button class="end-btn mx-1 text-white">End</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <a href="#">1SUP006</a>
                <clipboard-list-outline-icon (click)="[showDataModal = true, showPage = 'notes']" title="Notes">
                </clipboard-list-outline-icon>
              </td>
              <td>
                Microsoft
              </td>
              <td>
                <div class="position-relative d-flex align-items-center">
                  <div>
                    <document-text-outline-icon title="Contract"></document-text-outline-icon>
                    <shopping-bag-outline-icon title="Supported Product"></shopping-bag-outline-icon>
                    <document-report-outline-icon title="Supported Contents"></document-report-outline-icon>
                  </div>
                  <span class="px-2">Microsoft team support for 50 users</span>
                </div>
              </td>
              <td> 16-Feb-2021</td>
              <td> 22-Feb-2021</td>
              <td>8 Months</td>
              <td>$ a,aaa,aaa.aa</td>
              <td>$ b,bbb,bbb.bb</td>
              <td>$ ccc,ccc.cc</td>
              <td>$ ddd,ddd.dd</td>

              <td>
                <div class="d-flex">
                  <a href="#" routerLink="/support/edit" class="edit-btn px-3 py-1 rounded-pill text-xs">Edit</a>
                  <button class="renew-btn mx-1 text-white">Renew</button>
                  <button class="end-btn mx-1 text-white">End</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <a href="#">1SUP007</a>
                <clipboard-list-outline-icon (click)="[showDataModal = true, showPage = 'notes']" title="Notes">
                </clipboard-list-outline-icon>
              </td>
              <td>
                Microsoft
              </td>
              <td>
                <div class="position-relative d-flex align-items-center">
                  <div>
                    <document-text-outline-icon title="Contract"></document-text-outline-icon>
                    <shopping-bag-outline-icon title="Supported Product"></shopping-bag-outline-icon>
                    <document-report-outline-icon title="Supported Contents"></document-report-outline-icon>
                  </div>
                  <span class="px-2">Microsoft team support for 50 users</span>
                </div>
              </td>
              <td> 16-Feb-2021</td>
              <td> 22-Feb-2021</td>
              <td>8 Months</td>
              <td>$ a,aaa,aaa.aa</td>
              <td>$ b,bbb,bbb.bb</td>
              <td>$ ccc,ccc.cc</td>
              <td>$ ddd,ddd.dd</td>

              <td>
                <div class="d-flex">
                  <a href="#" routerLink="/support/edit" class="edit-btn px-3 py-1 rounded-pill text-xs">Edit</a>
                  <button class="renew-btn mx-1 text-white">Renew</button>
                  <button class="end-btn mx-1 text-white">End</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <a href="#">1SUP008</a>
                <clipboard-list-outline-icon (click)="[showDataModal = true, showPage = 'notes']" title="Notes">
                </clipboard-list-outline-icon>
              </td>
              <td>
                Microsoft
              </td>
              <td>
                <div class="position-relative d-flex align-items-center">
                  <div>
                    <document-text-outline-icon title="Contract"></document-text-outline-icon>
                    <shopping-bag-outline-icon title="Supported Product"></shopping-bag-outline-icon>
                    <document-report-outline-icon title="Supported Contents"></document-report-outline-icon>
                  </div>
                  <span class="px-2">Microsoft team support for 50 users</span>
                </div>
              </td>
              <td> 16-Feb-2021</td>
              <td> 22-Feb-2021</td>
              <td>8 Months</td>
              <td>$ a,aaa,aaa.aa</td>
              <td>$ b,bbb,bbb.bb</td>
              <td>$ ccc,ccc.cc</td>
              <td>$ ddd,ddd.dd</td>

              <td>
                <div class="d-flex">
                  <a href="#" routerLink="/support/edit" class="edit-btn px-3 py-1 rounded-pill text-xs">Edit</a>
                  <button class="renew-btn mx-1 text-white">Renew</button>
                  <button class="end-btn mx-1 text-white">End</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <a href="#">1SUP009</a>
                <clipboard-list-outline-icon (click)="[showDataModal = true, showPage = 'notes']" title="Notes">
                </clipboard-list-outline-icon>
              </td>
              <td>
                Microsoft
              </td>
              <td>
                <div class="position-relative d-flex align-items-center">
                  <div>
                    <document-text-outline-icon title="Contract"></document-text-outline-icon>
                    <shopping-bag-outline-icon title="Supported Product"></shopping-bag-outline-icon>
                    <document-report-outline-icon title="Supported Contents"></document-report-outline-icon>
                  </div>
                  <span class="px-2">Microsoft team support for 50 users</span>
                </div>
              </td>
              <td> 16-Feb-2021</td>
              <td> 22-Feb-2021</td>
              <td>8 Months</td>
              <td>$ a,aaa,aaa.aa</td>
              <td>$ b,bbb,bbb.bb</td>
              <td>$ ccc,ccc.cc</td>
              <td>$ ddd,ddd.dd</td>

              <td>
                <div class="d-flex">
                  <a href="#" routerLink="/support/edit" class="edit-btn px-3 py-1 rounded-pill text-xs">Edit</a>
                  <button class="renew-btn mx-1 text-white">Renew</button>
                  <button class="end-btn mx-1 text-white">End</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <a href="#">1SUP010</a>
                <clipboard-list-outline-icon (click)="[showDataModal = true, showPage = 'notes']" title="Notes">
                </clipboard-list-outline-icon>
              </td>
              <td>
                Microsoft
              </td>
              <td>
                <div class="position-relative d-flex align-items-center">
                  <div>
                    <document-text-outline-icon title="Contract"></document-text-outline-icon>
                    <shopping-bag-outline-icon title="Supported Product"></shopping-bag-outline-icon>
                    <document-report-outline-icon title="Supported Contents"></document-report-outline-icon>
                  </div>
                  <span class="px-2">Microsoft team support for 50 users</span>
                </div>
              </td>
              <td> 16-Feb-2021</td>
              <td> 22-Feb-2021</td>
              <td>8 Months</td>
              <td>$ a,aaa,aaa.aa</td>
              <td>$ b,bbb,bbb.bb</td>
              <td>$ ccc,ccc.cc</td>
              <td>$ ddd,ddd.dd</td>

              <td>
                <div class="d-flex">
                  <a href="#" routerLink="/support/edit" class="edit-btn px-3 py-1 rounded-pill text-xs">Edit</a>
                  <button class="renew-btn mx-1 text-white">Renew</button>
                  <button class="end-btn mx-1 text-white">End</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <a href="#">1SUP011</a>
                <clipboard-list-outline-icon (click)="[showDataModal = true, showPage = 'notes']" title="Notes">
                </clipboard-list-outline-icon>
              </td>
              <td>
                Microsoft
              </td>
              <td>
                <div class="position-relative d-flex align-items-center">
                  <div>
                    <document-text-outline-icon title="Contract"></document-text-outline-icon>
                    <shopping-bag-outline-icon title="Supported Product"></shopping-bag-outline-icon>
                    <document-report-outline-icon title="Supported Contents"></document-report-outline-icon>
                  </div>
                  <span class="px-2">Microsoft team support for 50 users</span>
                </div>
              </td>
              <td> 16-Feb-2021</td>
              <td> 22-Feb-2021</td>
              <td>8 Months</td>
              <td>$ a,aaa,aaa.aa</td>
              <td>$ b,bbb,bbb.bb</td>
              <td>$ ccc,ccc.cc</td>
              <td>$ ddd,ddd.dd</td>

              <td>
                <div class="d-flex">
                  <a href="#" routerLink="/support/edit" class="edit-btn px-3 py-1 rounded-pill text-xs">Edit</a>
                  <button class="renew-btn mx-1 text-white">Renew</button>
                  <button class="end-btn mx-1 text-white">End</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <a href="#">1SUP012</a>
                <clipboard-list-outline-icon (click)="[showDataModal = true, showPage = 'notes']" title="Notes">
                </clipboard-list-outline-icon>
              </td>
              <td>
                Microsoft
              </td>
              <td>
                <div class="position-relative d-flex align-items-center">
                  <div>
                    <document-text-outline-icon title="Contract"></document-text-outline-icon>
                    <shopping-bag-outline-icon title="Supported Product"></shopping-bag-outline-icon>
                    <document-report-outline-icon title="Supported Contents"></document-report-outline-icon>
                  </div>
                  <span class="px-2">Microsoft team support for 50 users</span>
                </div>
              </td>
              <td> 16-Feb-2021</td>
              <td> 22-Feb-2021</td>
              <td>8 Months</td>
              <td>$ a,aaa,aaa.aa</td>
              <td>$ b,bbb,bbb.bb</td>
              <td>$ ccc,ccc.cc</td>
              <td>$ ddd,ddd.dd</td>

              <td>
                <div class="d-flex">
                  <a href="#" routerLink="/support/edit" class="edit-btn px-3 py-1 rounded-pill text-xs">Edit</a>
                  <button class="renew-btn mx-1 text-white">Renew</button>
                  <button class="end-btn mx-1 text-white">End</button>
                </div>
              </td>
            </tr>

          </tbody>
        </table>

      </div>
    </div>
    <!-- Table end -->
    <div class="modal-wrapper" *ngIf="showDataModal">
      <!-- contract modal start -->
      <div class="half-page-modal animate__animated animate__slideInRight" *ngIf="showPage === 'contract-modal'">
        <div class="modal-close-button" (click)="[showPage = '',showDataModal= false]">&times;</div>
        <div class="half-page-modal-container">
          <h4 class="">Contract</h4>

          <div class="text-sm my-3">
            <p class="mb-1 text-sm font-bold">Contract Terms : 5 days</p>
            <table class="table text-sm support-table border mb-2">
              <thead>
                <tr>
                  <th>Sun</th>
                  <th>Mon</th>
                  <th>Tue</th>
                  <th>Wed</th>
                  <th>Thu</th>
                  <th>Fri</th>
                  <th>Sat</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>-</td>
                  <td><img src="assets/icons/checked.svg" width="14" height="14"></td>
                  <td><img src="assets/icons/checked.svg" width="14" height="14"></td>
                  <td><img src="assets/icons/checked.svg" width="14" height="14"></td>
                  <td><img src="assets/icons/checked.svg" width="14" height="14"></td>
                  <td><img src="assets/icons/checked.svg" width="14" height="14"></td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>-</td>
                  <td>8 AM - 8 PM</td>
                  <td>8 AM - 1 PM</td>
                  <td>8 AM - 6 PM</td>
                  <td>8 AM - 12 PM</td>
                  <td>8 AM - 9 AM</td>
                  <td>-</td>
                </tr>
              </tbody>

            </table>
            <div class="my-3">
              <p class="mb-1 text-sm font-bold">Service Level Agreements</p>
              <table class="table text-sm support-table border w-100 mb-1">
                <thead>
                  <tr>
                    <th style="color: #e53e3e;font-weight: 600;">Critical</th>
                    <th style="color:#f5a522;font-weight: 600;">High</th>
                    <th style="color:#818cf8;font-weight: 600;">Medium</th>
                    <th style="color:#34d399;font-weight: 600;">Low</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2 hrs</td>
                    <td>6 hrs</td>
                    <td>12 hrs</td>
                    <td>24 hrs</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- contract modal end -->
      <!-- supported product modal start -->
      <div class="half-page-modal animate__animated animate__slideInRight" *ngIf="showPage === 'supported-products'">
        <div class="modal-close-button" (click)="[showPage = '',showDataModal= false]">&times;</div>
        <div class="half-page-modal-container">
          <h4 class="">Supported Product</h4>

          <div class="text-sm my-3" style="min-width: 680px;">

            <table class="table text-sm support-table border w-100 mb-1">
              <thead>
                <tr>
                  <th width="15%">Vendor</th>
                  <th width="20%">Product name</th>
                  <th width="20%">Product version</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Avaya</td>
                  <td>Some text here</td>
                  <td>Some text here</td>
                  <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                </tr>
                <tr>
                  <td>Teleapps</td>
                  <td>Some text here</td>
                  <td>Some text here</td>
                  <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                </tr>
              </tbody>

            </table>
          </div>
        </div>
      </div>
      <!-- supported product modal end -->
      <!-- supported contents modal start -->
      <div class="half-page-modal animate__animated animate__slideInRight" *ngIf="showPage === 'supported-contents'">
        <div class="modal-close-button" (click)="[showPage = '',showDataModal= false]">&times;</div>
        <div class="half-page-modal-container" style="padding-bottom: unset;padding-right: unset;overflow: hidden;">
          <h4 class="">Contents</h4>

          <div class="text-sm mt-3 d-flex flex-column justify-content-between"
            style="min-width: 680px; max-width:1033px;height:89vh;overflow-y: hidden;">
            <!-- overflow:auto; -->
            <!-- height:-webkit-fill-available; -->
            <div style="width: 1033px; overflow-x: auto;max-height: 500px;overflow-y: auto;">
              <table class="table text-sm support-table border mb-1" style="width: max-content;">
                <thead>
                  <tr>
                    <th width="90px">Vendor</th>
                    <th width="90px">Item Code</th>
                    <th width="120px">Name</th>
                    <th width="180px">Description</th>
                    <th width="90px">Unit Buy</th>
                    <th width="60px">Qty</th>
                    <th width="120px" class="text-right">Total Buy</th>
                    <th width="90px">TA HD %</th>
                    <th width="90px">TA Uplift %</th>
                    <th width="90px">TA Sales %</th>
                    <th width="90px" class="text-right">Unit Sell</th>
                    <th width="90px" class="text-right">Total Sell</th>
                    <th width="90px" class="text-right">TA HD Base</th>
                    <th width="90px" class="text-right">TA HD Uplift</th>
                    <th width="90px" class="text-right">Sales Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Genesys</td>
                    <td>Gen 001</td>
                    <td>Genesys Cloud 1</td>
                    <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                    <td>20</td>
                    <td class="text-right">$ 100</td>
                    <td class="text-right"><span class="font-bold p-2 text-sm" style="background-color: lightblue;">$
                        2000</span></td>
                    <td>30</td>
                    <td>30</td>
                    <td>20</td>
                    <td class="text-right">$ 180</td>
                    <td class="text-right"><span class="font-bold p-2 text-sm" style="background-color: lightgreen;">$
                        3600</span></td>
                    <td class="text-right">$ 800</td>
                    <td class="text-right">$ 800</td>
                    <td class="text-right">$ 1600</td>
                  </tr>
                  <tr>
                    <td>Genesys</td>
                    <td>Gen 002</td>
                    <td>Genesys Cloud 2</td>
                    <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                    <td>20</td>
                    <td class="text-right">$ 100</td>
                    <td class="text-right"><span class="font-bold p-2 text-sm" style="background-color: lightblue;">$
                        2000</span></td>
                    <td>30</td>
                    <td>30</td>
                    <td>20</td>
                    <td class="text-right">$ 180</td>
                    <td class="text-right"><span class="font-bold p-2 text-sm" style="background-color: lightgreen;">$
                        3600</span></td>
                    <td class="text-right">$ 800</td>
                    <td class="text-right">$ 800</td>
                    <td class="text-right">$ 1600</td>
                  </tr>
                  <tr>
                    <td>Genesys</td>
                    <td>Gen 003</td>
                    <td>Genesys Cloud 3</td>
                    <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
                    <td>20</td>
                    <td class="text-right">$ 100</td>
                    <td class="text-right"><span class="font-bold p-2 text-sm" style="background-color: lightblue;">$
                        2000</span></td>
                    <td>30</td>
                    <td>30</td>
                    <td>20</td>
                    <td class="text-right">$ 180</td>
                    <td class="text-right"><span class="font-bold p-2 text-sm" style="background-color: lightgreen;">$
                        3600</span></td>
                    <td class="text-right">$ 800</td>
                    <td class="text-right">$ 800</td>
                    <td class="text-right">$ 1600</td>
                  </tr>
                </tbody>

              </table>
            </div>
            <div class="d-flex  flex-column align-items-end justify-content-end -mt-2 mr-2">
              <div class="font-bold mt-3" style="font-size: 0.9rem;">Total Buy : $ AAA,AAAA.AA</div>
              <div class="font-bold mt-3" style="font-size: 0.9rem;">Total Sell : $ BBB,BBB.BB</div>

              <div class="font-bold mt-3" style="font-size: 0.9rem;">TA Helpdesk Revenue : $ CCC,CCC.CC</div>
              <div class="font-bold mt-3" style="font-size: 0.9rem;">TA Sales Revenue : $ DDD,DDD.DD</div>
            </div>
          </div>
        </div>
      </div>
      <!-- supported contents modal end -->


    </div>

  </app-page-content>
  <div class="half-page-modal animate__animated animate__slideInRight" *ngIf="showPage === 'notes'"
    style="width: 70vw;">
    <div class="modal-close-button" (click)="[showPage = '',showDataModal= false]">&times;</div>
    <div class="half-page-modal-container">
      <h4 class="">Notes</h4>
      <div x-data="{ tabOpen: 'tabOne' }">
        <ul class="d-flex text-xs notes-tab mb-0 p-0">
          <li x-on:click.prevent="tabOpen = 'tabOne'" x-bind:class="{ 'active': tabOpen === 'tabOne'} "
            class="py-1 px-3 font-bold">List
          </li>
          <li x-on:click.prevent="tabOpen = 'tabTwo'" x-bind:class="{ 'active': tabOpen === 'tabTwo'} "
            class="py-1 px-3 font-bold mx-4">Add Note
          </li>

        </ul>
        <div x-show="tabOpen === 'tabOne'" class="">
          <table class="table table-striped table-sm mt-2">
            <thead>
              <tr>
                <th width="60%">Description</th>
                <th>Added On</th>
                <th>Added By</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam rutrum ex arcu, non
                  vestibulum massa lobortis ut. Quisque aliquet metus a condimentum venenatis.</td>
                <td class="table-cell">24-Jan-2021</td>
                <td class="table-cell">Raja Ram</td>
              </tr>
              <tr>
                <td class="table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam rutrum ex arcu, non
                  vestibulum massa lobortis ut. Quisque aliquet metus a condimentum venenatis.</td>
                <td class="table-cell">23-Jan-2021</td>
                <td class="table-cell">Raja Ram</td>
              </tr>
              <tr>
                <td class="table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam rutrum ex arcu, non
                  vestibulum massa lobortis ut. Quisque aliquet metus a condimentum venenatis.</td>
                <td class="table-cell">22-Jan-2021</td>
                <td class="table-cell">Raja Ram</td>
              </tr>
            </tbody>

          </table>
        </div>
        <div x-show="tabOpen === 'tabTwo'" class="border p-3 mt-2">
          <form>
            <div class="mb-3">
              <label class="text-xs font-bold">Note</label>
              <textarea class="form-control" rows="4"></textarea>
            </div>
            <div class="mb-2 pt-3">
              <a class="text-white bg-blue-dark px-4 py-2 rounded text-xs" href="#">Add </a>
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>