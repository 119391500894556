<div class="d-flex flex-column" style="min-width:calc(100vw - 180px);max-height:100vh; overflow-y: hidden;">
  <app-page-header>
    <div slot-breadcrumb>Timesheet</div>
    <div slot-heading>Time Sheets</div>
    <div slot-contextmenu>
      <li><a href="#">New Entry</a></li>
      <li><a href="#">Export Data</a></li>
    </div>
  </app-page-header>
  <app-page-content>
    Tickets List
  </app-page-content>
</div>
