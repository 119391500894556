/* tslint:disable:whitespace */
/* tslint:disable:max-line-length */

import { HeartSolidComponent } from "@dimaslz/ng-heroicons/lib/heroicons/solid/HeartSolid.component";

export const jobs = [
  {
    "id": "Job 001",
    "clientName": "3M Australia (TELSTRA)",
    "jobStatus": "Active",
    "country": "AUS",
    "primaryStaffName": "Jane Doe",
    "staffs": [
      {
        "staffId": "001",
        "staffName": "Manikandan"
      },
      {
        "staffId": "002",
        "staffName": "Hari Prakash"
      },
      {
        "staffId": "003",
        "staffName": "Mohan Ram"
      },
      {
        "staffId": "004",
        "staffName": "Raja Ram"
      },
      {
        "staffId": "004",
        "staffName": "Venkat Ram"
      },
    ],
    "jobName": "Providing EX Solution to 700 Remote Workers",
    "poDate": "22-Dec-2020",
    "startDate": "29-Dec-2020",
    "dueDate": "21-Jan-2021",
    "percentComplete": 70,
    "hoursBudgeted": 500,
    "hoursRemaining": 400,
    "lastNote": "This is last added note on Job 001.",
    "milestones": [
      {
        "milestoneId": "Milestone ID J 02 01",
        "milestoneName": "Project Kick Off",
        "milestoneDate": "21-Jan-2021",
      },
      {
        "milestoneId": "Milestone ID J 02 02 ",
        "milestoneName": "Setup Complete",
        "milestoneDate": "15-Jan-2021",
      },
      {
        "milestoneId": "Milestone ID J 02 03",
        "milestoneName": "Hand Over",
        "milestoneDate": "21-Feb-2021",
      }
    ],
    "notes": [
      {
        "noteId": "Note 001",
        "noteDescription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam rutrum ex arcu, non vestibulum massa lobortis ut. Quisque aliquet metus a condimentum venenatis.",
        "noteAddedOn": "24-Jan-2021",
        "noteAddedBy": "Raja Ram"
      },
      {
        "noteId": "Note 002",
        "noteDescription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam rutrum ex arcu, non vestibulum massa lobortis ut. Quisque aliquet metus a condimentum venenatis.",
        "noteAddedOn": "23-Jan-2021",
        "noteAddedBy": "Raja Ram"
      },
      {
        "noteId": "Note 003",
        "noteDescription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam rutrum ex arcu, non vestibulum massa lobortis ut. Quisque aliquet metus a condimentum venenatis.",
        "noteAddedOn": "22-Jan-2021",
        "noteAddedBy": "Raja Ram"
      },
      {
        "noteId": "Note 004",
        "noteDescription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam rutrum ex arcu, non vestibulum massa lobortis ut. Quisque aliquet metus a condimentum venenatis.",
        "noteAddedOn": "21-Jan-2021",
        "noteAddedBy": "Raja Ram"
      }
    ],
    "tasks": [
      {
        "taskId": "Task 001",
        "taskDescription": "Purchase WiFi Modems for Job001",
        "taskAssignedTo": "Raja Raman",
        "taskStatus": "In Complete",
        "taskStartAt": "12-Dec-2020",
        "taskDueAt": "21-Dec-2020"
      },
      {
        "taskId": "Task 002",
        "taskDescription": "Purchase BlueTooth Head Phones for Job001",
        "taskAssignedTo": "Raja Raman",
        "taskStatus": "In Complete",
        "taskStartAt": "13-Dec-2020",
        "taskDueAt": "29-Dec-2020"
      },
      {
        "taskId": "Task 003",
        "taskDescription": "Install Modems for Job001",
        "taskAssignedTo": "Raja Raman",
        "taskStatus": "In Complete",
        "taskStartAt": "10-Dec-2020",
        "taskDueAt": "21-Dec-2020"
      },
      {
        "taskId": "Task 005",
        "taskDescription": "Install Modems for Job001",
        "taskAssignedTo": "Raja Raman",
        "taskStatus": "In Complete",
        "taskStartAt": "10-Dec-2020",
        "taskDueAt": "21-Dec-2020"
      }
    ]

  },
  {
    "id": "Job 211",
    "clientName": "GegoSoft Technologies Private Ltd",
    "jobStatus": "On Hold",
    "country": "AUS",
    "primaryStaffName": "Jane Doe",
    "staffs": [
      {
        "staffId": "001",
        "staffName": "Manikandan"
      },
      {
        "staffId": "002",
        "staffName": "Hari Prakash"
      },
      {
        "staffId": "003",
        "staffName": "Mohan Ram"
      },
      {
        "staffId": "004",
        "staffName": "Raja Ram"
      },
      {
        "staffId": "005",
        "staffName": "Venkat Ram"
      },
    ],
    "jobName": "Install Sparza Collabration Tool",
    "poDate": "22-Dec-2020",
    "startDate": "29-Dec-2020",
    "dueDate": "21-Jan-2021",
    "percentComplete": 50,
    "hoursBudgeted": 500,
    "hoursRemaining": 400,
    "milestones": [
      {
        "milestoneId": "Milestone ID J 02 01",
        "milestoneName": "Project Kick Off",
        "milestoneDate": "21-Jan-2021",
      },
      {
        "milestoneId": "Milestone ID J 02 02 ",
        "milestoneName": "Setup Complete",
        "milestoneDate": "15-Jan-2021",
      },
      {
        "milestoneId": "Milestone ID J 02 03",
        "milestoneName": "Hand Over",
        "milestoneDate": "21-Feb-2021",
      }
    ],
    "lastNote": "This is last added note on Job 002.",
    "notes": [
      {
        "noteId": "Job 2 Note 001",
        "noteDescription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam rutrum ex arcu, non vestibulum massa lobortis ut. Quisque aliquet metus a condimentum venenatis.",
        "noteAddedOn": "24-Jan-2021",
        "noteAddedBy": "Raja Ram"
      },
      {
        "noteId": "Job 2 Note 002",
        "noteDescription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam rutrum ex arcu, non vestibulum massa lobortis ut. Quisque aliquet metus a condimentum venenatis.",
        "noteAddedOn": "21-Jan-2021",
        "noteAddedBy": "Raja Ram"
      }
    ],
    "tasks": [
      {
        "taskId": "Task j2-001",
        "taskDescription": "Purchase WiFi Modems",
        "taskAssignedTo": "Raja Raman",
        "taskStatus": "In Complete",
        "taskStartAt": "10-Dec-2020",
        "taskDueAt": "29-Dec-2020"
      },
      {
        "taskId": "Task j2-002",
        "taskDescription": "Purchase BlueTooth Head Phones",
        "taskAssignedTo": "Raja Raman",
        "taskStatus": "In Complete",
        "taskStartAt": "10-Dec-2020",
        "taskDueAt": "29-Dec-2020"
      },
      {
        "taskId": "Task j2-003",
        "taskDescription": "Install Modems",
        "taskAssignedTo": "Raja Raman",
        "taskStatus": "In Complete",
        "taskStartAt": "10-Dec-2020",
        "taskDueAt": "21-Dec-2020"
      }
    ]

  },
  {
    "id": "Job 311",
    "clientName": "MicroSoft Technologies Private Ltd",
    "jobStatus": "On Hold",
    "country": "AUS",
    "primaryStaffName": "John Doe",
    "staffs": [
      {
        "staffId": "001",
        "staffName": "Manikandan"
      },
      {
        "staffId": "002",
        "staffName": "Hari Prakash"
      },
      {
        "staffId": "003",
        "staffName": "Mohan Ram"
      },
      {
        "staffId": "004",
        "staffName": "Raja Ram"
      },
    ],
    "jobName": "Install Sparza Collabration Tool",
    "poDate": "22-Dec-2020",
    "startDate": "29-Dec-2020",
    "dueDate": "21-Jan-2021",
    "percentComplete": 30,
    "hoursBudgeted": 500,
    "hoursRemaining": 400,
    "milestones": [
      {
        "milestoneId": "Milestone ID J 03 01",
        "milestoneName": "Project Kick Off",
        "milestoneDate": "21-Jan-2021",
      },
      {
        "milestoneId": "Milestone ID J 02 02 ",
        "milestoneName": "Setup Complete",
        "milestoneDate": "15-Jan-2021",
      },
      {
        "milestoneId": "Milestone ID J 03 03",
        "milestoneName": "Hand Over",
        "milestoneDate": "21-Feb-2021",
      }
    ],
    "lastNote": "This is last added note on Job 003.",
    "notes": [
      {
        "noteId": "Job 3 Note 001",
        "noteDescription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam rutrum ex arcu, non vestibulum massa lobortis ut. Quisque aliquet metus a condimentum venenatis.",
        "noteAddedOn": "24-Jan-2021",
        "noteAddedBy": "Raja Ram"
      },
      {
        "noteId": "Job 3 Note 002",
        "noteDescription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam rutrum ex arcu, non vestibulum massa lobortis ut. Quisque aliquet metus a condimentum venenatis.",
        "noteAddedOn": "21-Jan-2021",
        "noteAddedBy": "Raja Ram"
      }
    ],
    "tasks": [
      {
        "taskId": "Task J3 001",
        "taskDescription": "Purchase WiFi Modems",
        "taskAssignedTo": "Raja Raman",
        "taskStatus": "In Complete",
        "taskStartAt": "10-Dec-2020",
        "taskDueAt": "29-Dec-2020"
      },
      {
        "taskId": "Task J3 002",
        "taskDescription": "Purchase BlueTooth Head Phones",
        "taskAssignedTo": "Raja Raman",
        "taskStatus": "In Complete",
        "taskStartAt": "10-Dec-2020",
        "taskDueAt": "29-Dec-2020"
      },
      {
        "taskId": "Task J3 003",
        "taskDescription": "Install Modems",
        "taskAssignedTo": "Raja Raman",
        "taskStatus": "In Complete",
        "taskStartAt": "10-Dec-2020",
        "taskDueAt": "21-Dec-2020"
      }
    ]

  },

];
